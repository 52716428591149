"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
exports.__esModule = true;
var log_1 = require("../util/log");
/**
 * 小程序日志类
 */
var ExtLogger = /** @class */ (function () {
    function ExtLogger(options) {
        this.logFunc = options.log;
        this.prefix = options.prefix;
    }
    /**
     * 获取全局logger，例如记录拉小程序列表的结果
     * @param log 记录日志的具体实现，可以由适配器提供
     */
    ExtLogger.getGlobalLogger = function (log) {
        return new ExtLogger({ prefix: 'EXT_global', log: log });
    };
    /**
     * 获取某个小程序的系统logger，例如记录小程序开始加载、结束加载、加载错误等
     * @param options 初始化参数
     */
    ExtLogger.getExtSysLogger = function (options) {
        return new ExtLogger({
            prefix: "EXT_" + options.extUuid + "-" + options.extType + "-" + options.extUuid + "_sys",
            log: options.log
        });
    };
    /**
     * 获取某个小程序的业务logger，调用SDK接口记录日志会调用这个logger进行记录
     * @param options 初始化参数
     */
    ExtLogger.getExtBusiLogger = function (options) {
        return new ExtLogger({
            prefix: "EXT_" + options.extUuid + "-" + options.extType + "-" + options.extUuid + "_busi",
            log: options.log
        });
    };
    /**
     * 记录日志
     * @param level 日志级别
     * @param args 参数
     */
    ExtLogger.prototype.log = function (level) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (args.length <= 1) {
            this.logFunc(log_1.logTime() + " " + this.prefix + " " + level + " " + String(args[0]));
            if (process.env.NODE_ENV === 'development') {
                console.info(log_1.logTime() + " " + this.prefix + " " + level + " " + String(args[0]));
            }
        }
        else {
            var logData = log_1.trimArray(args.slice(1));
            var logDataStr = JSON.stringify(logData.length <= 1
                ? logData[0]
                : logData);
            this.logFunc(log_1.logTime() + " " + this.prefix + " " + level + " " + String(args[0]) + " " + logDataStr);
            if (process.env.NODE_ENV === 'development') {
                console.info.apply(console, __spreadArrays([log_1.logTime() + " " + this.prefix + " " + level + " " + String(args[0])], logData));
            }
        }
    };
    /**
     * 记录DEBUG级别的日志
     * @param args 参数
     */
    ExtLogger.prototype.debug = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (process.env.NODE_ENV === 'development') {
            this.log.apply(this, __spreadArrays(['DEBUG'], args));
        }
    };
    /**
     * 记录INFO级别的日志
     * @param args 参数
     */
    ExtLogger.prototype.info = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.log.apply(this, __spreadArrays(['INFO'], args));
    };
    /**
     * 记录WRAN级别的日志
     * @param args 参数
     */
    ExtLogger.prototype.warn = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.log.apply(this, __spreadArrays(['WARN'], args));
    };
    /**
     * 记录ERROR级别的日志
     * @param args 参数
     */
    ExtLogger.prototype.error = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.log.apply(this, __spreadArrays(['ERROR'], args));
    };
    return ExtLogger;
}());
exports["default"] = ExtLogger;
