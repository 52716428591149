import { ExtAdapter, trimHttp, wasmGenUnionId } from "@hyext-inner/ext-web-comp"
import HyExtAdapter from "../modules/HyExtAdapter"

export const barrageJceToBarrageInfo = async (data:HUYA.MessageNotice, extMain:MidExtQuery.ExtMain, adapter:ExtAdapter)
:Promise<BarrageInfo> => {
  const { unionId } = await wasmGenUnionId(String(data.tUserInfo.lUid), extMain.authorAppId, adapter)
  const { tUserInfo, vDecorationPrefix, sContent } = data
  const { sNickName, sAvatarUrl, iGender, tNobleLevelInfo } = tUserInfo
  const { iNobleLevel, iAttrType } = tNobleLevelInfo
  const fansInfo = vDecorationPrefix.value.filter((v:any) => v.iAppId === 10400)[0]
  let iBadgeLevel = 0
  if (fansInfo) {
    iBadgeLevel = parseInt(fansInfo.vData.iBadgeLevel)
  }

  return {
    sendNick: sNickName,
    senderAvatarUrl: trimHttp(sAvatarUrl),
    senderGender: iGender,
    nobleLevel: parseInt(iNobleLevel, 10),
    nobleAttrType: parseInt(iAttrType, 10),
    fansLevel: iBadgeLevel,
    content: sContent,
    unionId
  }
}

export const giftJceToGiftInfo = async (data:HUYA.SendItemSubBroadcastPacket, extMain:MidExtQuery.ExtMain, adapter:ExtAdapter):Promise<GiftInfo> => {
  const { unionId } = await wasmGenUnionId(String(data.lSenderUid), extMain.authorAppId, adapter)
  const { iItemType, sSenderNick, iSenderIcon, sPropsName, iItemCount, iItemGroup, iNobleLevel, lRoomId, tNobleLevel } = data
  // let fansData = vDecorationPrefix.value.filter(v => v.iAppId === 10400)[0]
  return {
    itemId: iItemType,
    sendNick: sSenderNick,
    senderAvatarUrl: trimHttp(iSenderIcon),
    itemName: sPropsName,
    sendItemCount: iItemCount,
    sendItemComboHits: iItemGroup,
    nobleLevel: iNobleLevel,
    // fansLevel: fansData ? fansData.vData.iBadgeLevel : 0,
    // badgeName: fansData ? fansData.vData.sBadgeName : '',
    roomId: lRoomId,
    nobleAttrType: tNobleLevel.iAttrType !== undefined ? tNobleLevel.iAttrType : '',
    unionId
  }
}

export const nobleInfoToOpenNobleInfo = async (data:HUYA.NobleInfo, extMain:MidExtQuery.ExtMain, adapter:ExtAdapter):Promise<OpenNobleInfo> => {
  const { lStartTime, lEndTime, iOpenFlag, iMonths, iLevel, sName, sLogoUrl, sNickName, lUid, lRoomId, tLevel } = data
  const { unionId } = await wasmGenUnionId(String(lUid), extMain.authorAppId, adapter)
  const userLevel = await (adapter as HyExtAdapter).getUserLevelByUid(lUid)
  return {
    startTime: lStartTime,
    endTime: lEndTime,
    openFlag: iOpenFlag,
    months: iMonths,
    nobleLevel: iLevel,
    nobleName: sName,
    nobleAttrType: tLevel.iAttrType || 0,
    roomId: Number(lRoomId),
    unionId,
    userAvatarUrl: trimHttp(sLogoUrl),
    userLevel,
    userNick: sNickName
  }
}

export const nobleNoticeToOpenNobleInfo = async (data:HUYA.NobleNotice, extMain:MidExtQuery.ExtMain, adapter:ExtAdapter):Promise<OpenNobleInfo> => {
  return nobleInfoToOpenNobleInfo(data.tNobleInfo, extMain, adapter)
}

export const nobleLevelNoticeToOpenNobleInfo = async (data:HUYA.NobleLevelNotice, extMain:MidExtQuery.ExtMain, adapter:ExtAdapter):Promise<OpenNobleInfo> => {
  return nobleInfoToOpenNobleInfo(data.tNobleInfo, extMain, adapter)
}

export const guardianPresenterInfoNoticeToOpenGuardianInfo = async (data:HUYA.GuardianPresenterInfoNotice, extMain:MidExtQuery.ExtMain, adapter:ExtAdapter):Promise<OpenGuardianInfo> => {
  const hyExtAdpater = adapter as HyExtAdapter
  const { iOpenDays, iLastLevel, iLevel, sNick, sGuardianLogo, sGuardianNick, lGuardianUid } = data
  const roomId = hyExtAdpater.TTR.profileRoom
  const { unionId } = await wasmGenUnionId(String(lGuardianUid), extMain.authorAppId, adapter)
  const userLevel = await (adapter as HyExtAdapter).getUserLevelByUid(lGuardianUid)
  return {
    startTime: 0,
    endTime: 0,
    openDays: iOpenDays,
    lastGuardianLevel: iLastLevel,
    openGuardianLevel: iLevel,
    presenterNick: sNick,
    roomId: Number(roomId),
    unionId,
    userAvatarUrl: trimHttp(sGuardianLogo),
    userLevel,
    userNick: sGuardianNick
  }
}

export const vipEnterBannerToVipEnterInfo = async (data:HUYA.VipEnterBanner, extMain:MidExtQuery.ExtMain, adapter:ExtAdapter):Promise<VipEnterInfo> => {
  const hyExtAdapter = adapter as HyExtAdapter
  const { tDecorationInfo, tNobleInfo, sLogoURL, sNickName, lUid } = data
  const { vDecorationPrefix } = tDecorationInfo
  const fansInfo = vDecorationPrefix.value.filter(v => v.iAppId === 10400)[0]
  let badgeName = ''
  let fansLevel = 0
  if (fansInfo) {
    badgeName = fansInfo.vData.sBadgeName
    fansLevel = fansInfo.vData.iBadgeLevel
  }
  const roomId = hyExtAdapter.TTR.profileRoom
  const { unionId } = await wasmGenUnionId(String(lUid), extMain.authorAppId, adapter)
  const userLevel = await (adapter as HyExtAdapter).getUserLevelByUid(lUid)
  return {
    badgeName,
    fansLevel,
    nobleLevel: tNobleInfo.iNobleLevel,
    nobleName: tNobleInfo.sNobleName,
    nobleAttrType: (tNobleInfo.tLevelAttr && tNobleInfo.tLevelAttr.iAttrType) || 0,
    unionId,
    roomId: Number(roomId),
    userAvatarUrl: sLogoURL.replace('http:', 'https:'),
    userNick: sNickName,
    userLevel
  }
}