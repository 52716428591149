"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
exports.__esModule = true;
exports.CommonExtComp = exports.CommonExtPopupEndpointModule = exports.CommonExtPanelEndpointModule = exports.CommonExtAdapter = exports.wasmGenUnionId = exports.ExtUserAuth = exports.ExtComp = exports.ExtFunctionalProvider = exports.ExtEndpointModule = exports.ExtLogger = exports.ExtError = exports.ExtFrameMessage = exports.ExtFrame = exports.ExtAdapter = void 0;
var ExtAdapter_1 = require("./modules/ExtAdapter");
__createBinding(exports, ExtAdapter_1, "default", "ExtAdapter");
var ExtFrame_1 = require("./ext/ExtFrame");
__createBinding(exports, ExtFrame_1, "default", "ExtFrame");
var ExtFrameMessage_1 = require("./ext/ExtFrameMessage");
__createBinding(exports, ExtFrameMessage_1, "default", "ExtFrameMessage");
var ExtError_1 = require("./modules/ExtError");
__createBinding(exports, ExtError_1, "default", "ExtError");
var ExtLogger_1 = require("./modules/ExtLogger");
__createBinding(exports, ExtLogger_1, "default", "ExtLogger");
var ExtEndpointModule_1 = require("./ext/ExtEndpointModule");
__createBinding(exports, ExtEndpointModule_1, "default", "ExtEndpointModule");
var ExtFunctionalProvider_1 = require("./modules/ExtFunctionalProvider");
__createBinding(exports, ExtFunctionalProvider_1, "default", "ExtFunctionalProvider");
var ExtComp_1 = require("./modules/ExtComp");
__createBinding(exports, ExtComp_1, "default", "ExtComp");
var ExtUserAuth_1 = require("./ext/ExtUserAuth");
__createBinding(exports, ExtUserAuth_1, "default", "ExtUserAuth");
__exportStar(require("./modules/ExtAdapter"), exports);
__exportStar(require("./modules/ExtError"), exports);
__exportStar(require("./util/ext"), exports);
__exportStar(require("./util"), exports);
var wasm_1 = require("./util/wasm");
__createBinding(exports, wasm_1, "wasmGenUnionId");
var CommonExtAdapter_1 = require("./common/CommonExtAdapter");
__createBinding(exports, CommonExtAdapter_1, "default", "CommonExtAdapter");
__exportStar(require("./common/CommonExtType"), exports);
var CommonExtPanelEndpointModule_1 = require("./common/CommonExtPanelEndpointModule");
__createBinding(exports, CommonExtPanelEndpointModule_1, "default", "CommonExtPanelEndpointModule");
var CommonExtPopupEndpointModule_1 = require("./common/CommonExtPopupEndpointModule");
__createBinding(exports, CommonExtPopupEndpointModule_1, "default", "CommonExtPopupEndpointModule");
var CommonExtComp_1 = require("./common/CommonExtComp");
__createBinding(exports, CommonExtComp_1, "default", "CommonExtComp");
