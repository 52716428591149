import { ExtAdapter, ExtNotSupportError, ExtParamInvalidError, trimHttp, wasmGenUnionId } from '@hyext-inner/ext-web-comp'
import { InnerExtFunctionalProvider } from '@hyext-inner/inner-ext-web-comp'
import { HyExtType } from '../util/hy'
import { reportProductEvent } from '../util/ya'
import HyExtWebPopupEndpointModule from './ext/HyExtWebPopupEndpointModule'
import HyExtAdapter from './HyExtAdapter'

export default class HyExtFunctionalProvider extends InnerExtFunctionalProvider {
  // 消息相关
  // 订阅相关
  getSubscribeInfo(_:any, adapter:ExtAdapter):Promise<boolean> {
    const hyExtAdapter = adapter as HyExtAdapter
    return Promise.resolve(hyExtAdapter.roomFollow.state)
  }
  // 礼物相关
  async getGiftConf(_:any, adapter:ExtAdapter):Promise<GetGiftConfRsp> {
    const hyExtAdapter = adapter as HyExtAdapter
    const props = await hyExtAdapter.getGiftData()
    if (props) {
      return Promise.resolve(props.map(p => {
        const giftId = Number(p.iPropsId)
        const { sPropsPic108, sPropsWeb } = p.vPropsIdentity.value[0] || {}
        let giftGif = ''
        try {
          giftGif = p.vPropsIdentity.value[0].sPropsPicGif.replace('&', '?')
        } catch (_) {}

        return {
          giftId,
          giftName: p.sPropsName,
          giftLogo: (sPropsPic108 || sPropsWeb || '').replace('&', '?'),
          giftGif,
          giftPriceHuya: parseFloat((p.iPropsYb * 0.01).toFixed(2)),
          giftPriceGreenBean: p.iPropsGreenBean,
          giftPriceWhiteBean: p.iPropsWhiteBean
        }
      }))
    } else {
      return Promise.resolve([])
    }
  }
  // 弹幕相关
  setSendBarrageTag(req:SetSendBarrageTagReq, adapter:ExtAdapter):Promise<void> {
    if (req && String(req.tag)) {
      return (adapter as HyExtAdapter).setSendBarrageTag(String(req.tag))
    } else {
      return Promise.reject(new ExtParamInvalidError('必须提供tag参数'))
    }
  }
  setDisplayBarrageTags(req:SetDisplayBarrageTagsReq, adapter:ExtAdapter):Promise<void> {
    if (req && req.tags && req.tags.length > 0) {
      return (adapter as HyExtAdapter).setDisplayBarrageTags(req.tags)
    } else {
      return Promise.reject(new ExtParamInvalidError('必须提供tags参数或者tags的长度必须大于1'))
    }
  }
  // UI相关
  async showToast(req:ShowToastReq, adapter:ExtAdapter):Promise<void> {
    if (req && req.msg) {
      try {
        await this.reportText({ text: req.msg }, adapter)
        return (adapter as HyExtAdapter).showToast(req.msg)
      } catch (err) {
        return Promise.reject(err)
      }
    } else {
      return Promise.reject(new ExtParamInvalidError('必须传入toast的内容'))
    }
  }
  // 存储相关
  // 用户相关
  // 直播间相关
  getLiveInfo(_:any, adapter:ExtAdapter):Promise<GetLiveInfoRsp> {
    return Promise.resolve((adapter as HyExtAdapter).liveInfo)
  }
  async getWeekRank(_:any, adapter:ExtAdapter):Promise<GetWeekRankRsp> {
    const weekRank = await (adapter as HyExtAdapter).getWeekRank()
    const unionIds = await Promise.all(weekRank.map(item => wasmGenUnionId(String(item.lUid), this.extMain.authorAppId, adapter)))
    return weekRank.map((r, index) => ({
      userNick: r.sNickName,
      userAvatarUrl: r.sLogo,
      score: Number(r.iScore),
      unionId: unionIds[index].unionId
    }))
  }
  async getFansRank(_:any, adapter:ExtAdapter):Promise<GetFansRankRsp> {
    const fansRank = await (adapter as HyExtAdapter).getFansRank()
    const unionIds = await Promise.all(fansRank.vItem.value.map(item => wasmGenUnionId(String(item.lUid), this.extMain.authorAppId, adapter)))
    return {
      badgeName: fansRank.sBadgeName,
      rank: fansRank.vItem && fansRank.vItem.value
        ? fansRank.vItem.value.map((item, index) => ({
          userNick: item.sNickName,
          userAvatarUrl: item.sLogo,
          score: Number(item.iScore),
          fansLevel: Number(item.iBadgeLevel),
          unionId: unionIds[index].unionId
        }))
        : []
    }
  }
  async getVip(_:any, adapter:ExtAdapter):Promise<GetVipRsp> {
    const vipInfo = await (adapter as HyExtAdapter).getVip()
    const unionIds = await Promise.all(vipInfo.list.map(item => wasmGenUnionId(String(item.lUid), this.extMain.authorAppId, adapter)))
    return vipInfo.list.map((item, index) => ({
      userNick: item.sNickName,
      userAvatarUrl: item.sLogo,
      nobleLevel: Number(item.tNobleInfo.iNobleLevel),
      nobleName: item.tNobleInfo.sNobleName,
      unionId: unionIds[index].unionId,
      nobleAttrType: Number((item.tNobleInfo.tLevelAttr && item.tNobleInfo.tLevelAttr.iAttrType) || 0)
    }))
  }
  async getStreamerInfo(_:any, adapter:ExtAdapter):Promise<GetStreamerInfoRsp> {
    const hyExtAdapter = adapter as HyExtAdapter
    const streamerNick = hyExtAdapter.TTA.nick
    const streamerAvatarUrl = trimHttp(hyExtAdapter.TTA.avatar || '')
    const streamerSex = hyExtAdapter.TTA.sex
    const streamerRoomId = hyExtAdapter.TTA.profileRoom
    const { lUid } = await adapter.getUserId()
    const { unionId:streamerUnionId } = await wasmGenUnionId(String(lUid), this.extMain.authorAppId, adapter)
    let streamerLevel = 0
    try {
      streamerLevel = await hyExtAdapter.getStreamerLevel() 
    } catch (err) {
      this.logger.warn('获取主播等级失败', { err: err.message })
    }
    return { streamerNick, streamerAvatarUrl, streamerSex, streamerRoomId, streamerUnionId, streamerLevel }
  }
  // 容器相关
  // 流相关
  getViewerLatencyMode(_:any, adapter:ExtAdapter):Promise<GetViewerLatencyModeRsp> {
    return (adapter as HyExtAdapter).getViewerLatencyMode()
  }
  setViewerLatencyMode(req:SetViewerLatencyModeReq, adapter:ExtAdapter):Promise<void> {
    return (adapter as HyExtAdapter).setViewerLatencyMode(req)
  }
  // 文件相关
  // 直播间行为相关
  localControlEntrance(req:LocalControlEntranceReq, adapter:ExtAdapter):Promise<void> {
    const { extType, visible } = req
    if (extType !== HyExtType.WEB_VIDEO_COM) {
      return Promise.reject(new ExtNotSupportError('该操作仅支持虎牙主站面板(web_video_com)小程序类型'))
    } else {
      const extEndpointModuleList = adapter.extEndpointModulesManager.getExtEndpointModuleListWithExtUuid(this.extMain.extUuid)
      const extEndpointModule = extEndpointModuleList.find(e => e.extEndpoint.extType === extType)
      if (!extEndpointModule) {
        return Promise.reject(new ExtNotSupportError('没有对应的小程序类型'))
      } else {
        if (visible) {
          extEndpointModule.showExtEntrance(adapter)
        } else {
          extEndpointModule.hideExtEntrance(adapter)
        }
        return Promise.resolve()
      }
    }
  }
  localControlPanelLoad(req:LocalControlPanelLoadReq, adapter:ExtAdapter):Promise<void> {
    const { extType, load } = req
    if (extType !== HyExtType.WEB_POPUP) {
      return Promise.reject(new ExtNotSupportError('该操作仅支持虎牙主站浮窗(web_popup)小程序类型'))
    } else {
      const extEndpointModuleList = adapter.extEndpointModulesManager.getExtEndpointModuleListWithExtUuid(this.extMain.extUuid)
      const extEndpointModule = extEndpointModuleList.find(e => e.extEndpoint.extType === extType)
      if (!extEndpointModule) {
        return Promise.reject(new ExtNotSupportError('没有对应的小程序类型'))
      } else {
        if (load) {
          (extEndpointModule as HyExtWebPopupEndpointModule).loadPopup(adapter)
        } else {
          extEndpointModule.hidePanel(adapter)
          ;(extEndpointModule as HyExtWebPopupEndpointModule).unloadPopup(adapter)
        }
        return Promise.resolve()
      }
    }
  }
  async localControlPanelVisible(req:LocalControlPanelVisibleReq, adapter:ExtAdapter):Promise<void> {
    const { extType, visible } = req
    if (extType !== HyExtType.WEB_VIDEO_COM) {
      return Promise.reject(new ExtNotSupportError('该操作仅支持虎牙主站面板(web_video_com)小程序类型'))
    } else {
      const extEndpointModuleList = adapter.extEndpointModulesManager.getExtEndpointModuleListWithExtUuid(this.extMain.extUuid)
      const extEndpointModule = extEndpointModuleList.find(e => e.extEndpoint.extType === extType)
      if (!extEndpointModule) {
        return Promise.reject(new ExtNotSupportError('没有对应的小程序类型'))
      } else {
        if (visible) {
          await extEndpointModule.ready(adapter)
          extEndpointModule.emit('postInitResolved')
          extEndpointModule.extFrame.load(adapter)
          extEndpointModule.showPanel(adapter)
        } else {
          extEndpointModule.hidePanel(adapter)
        }
        return Promise.resolve()
      }
    }
  }
  // 高级接口
  async currentChannelInfo(_:any, adapter:ExtAdapter):Promise<CurrentChannelInfoRsp> {
    const hyExtAdapter = adapter as HyExtAdapter
    const userInfo = await hyExtAdapter.getHyUserInfo()
    return Promise.resolve({
      aSid: '',
      topSid: hyExtAdapter.TTR.id, // 顶级频道
      subSid: hyExtAdapter.TTR.sid, // 子频道
      presenterId: adapter.getStreamerUid(), // 主播uid
      presenterName: hyExtAdapter.TTA.nick, // 主播昵称
      currentNickName: userInfo.userNick, // 当前用户昵称
      currentUid: userInfo.uid, // 当前用户uid
      currentLogoUrl: userInfo.userLogo, // 当前用户头像
      presenterLogoUrl: hyExtAdapter.TTA.avatar, // 主播头像
      durationAfterJoinSuccess: 0, // 观看时长，秒
      gameId: hyExtAdapter.TTR.gid,
      subscribeState: hyExtAdapter.roomFollow.state ? 1 : 0, // 1,已订阅当前主播， 0，未订阅
      isLiving: hyExtAdapter.liveInfo.isOn ? 1 : 0 // 1正在直播，0不在直播
    })
  }
  async showLogin(_:any, adapter:ExtAdapter):Promise<void> {
    const hyExtAdapter = adapter as HyExtAdapter
    const userInfo = await hyExtAdapter.getHyUserInfo()
    if (!Number(userInfo.uid)) {
      hyExtAdapter.TT.login.login()
      return Promise.resolve()
    } else {
      return Promise.reject(new ExtNotSupportError('用户已登录'))
    }
  }
  async getCurrentUserInfo(_:any, adapter:ExtAdapter):Promise<GetCurrentUserInfoRsp> {
    const hyExtAdapter = adapter as HyExtAdapter
    const userInfo = await hyExtAdapter.getHyUserInfo()
    const r = {
      imid: 0,
      uid: userInfo.uid,
      status: 'ok',
      err_code: 0,
      udbToken: '',
      ticketType: '',
      avatarUrl: userInfo.userLogo,
      sex: '',
      name: userInfo.userNick,
      sign: '',
      userLevel: 0,
      userCurrentExp: 0,
      nextLevelExp: 0,
      dailyExp: 0
    }
    if (userInfo.uid) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'https://www.huya.com/member/task.php?m=User&do=listTotal&callback=?',
          dataType: 'jsonp',
          success: json => {
            resolve({
              ...r,
              userLevel: json.data.level.userLevel,
              userCurrentExp: Number(json.data.level.currLevelExp),
              nextLevelExp: Number(json.data.level.nextLevelExp),
              dailyExp: json.data.level.dailyIncExp,
              nobleInfo: {
                isNoble: !!json.data.noble.nobleLevel, // 是否是贵族
                uid: Number(userInfo.uid),
                validDate: json.data.noble.levelAttr.lValidDate, // 贵族有效期的时间戳
                nobleName: json.data.noble.nobleName, // 贵族名称
                nobleLevel: json.data.noble.nobleLevel, // 贵族等级(6:大帝,5:君王,4:公爵)
                nobleNick: userInfo.userNick, // 用户昵称
                channelInfo: {
                  tid: hyExtAdapter.TTR.id, // 顶级频道
                  sid: hyExtAdapter.TTR.sid, // 子频道
                  pid: adapter.getStreamerUid(), // 主播id
                  presenterNick: hyExtAdapter.TTA.nick, // 主播昵称
                  // TODO
                  isGuard: false, // 是否对应主播的守护
                  guardLevel: 1, // 守护等级
                  isFans: true, // 是否对应主播的粉丝
                  fansLevel: 1, // 粉丝等级
                  fansName: 'xx' // 粉丝名称
                }
              }
            })
          },
          error: _ => {
            resolve({
              ...r
            })
          }
        })
      })
    } else {
      return Promise.resolve({
        ...r
      })
    }
  }
  async getTid(_:any, adapter:ExtAdapter):Promise<GetTidRsp> {
    const { lUid, sGuid, sToken, sHuYaUA, sCookie, iTokenType } = await adapter.getUserId()
    return {
      tId: { 
        lUid: Number(lUid), sGuid, sToken, sHuYaUA, sCookie, iTokenType
      }
    }
  }
  addActionEventWithExtraInfo(req:AddActionEventWithExtraInfoReq, adapter:ExtAdapter):Promise<void> {
    return reportProductEvent(req.eid, req.label, JSON.stringify(req.extraInfo
      ? req.extraInfo.prop
          ? req.extraInfo.prop
          : req.extraInfo
      : {}
    ))
  }
  openUrl(req:OpenUrlReq, adapter:ExtAdapter):Promise<void> {
    return (adapter as HyExtAdapter).openUrl(req.url)
  }
  async getJoinMicUserList(_:any, adapter:ExtAdapter):Promise<Array<JoinMicUserItem>> {
    return (adapter as HyExtAdapter).getJoinMicUserList()
  }
  // 友商接口
  // 环境相关
  async getHostInfo(_:any, adapter:ExtAdapter):Promise<GetHostInfoRsp> {
    return Promise.resolve({
      name: 'huya_web',
      platform: 'web',
      version: 'latest',
      baseVersion: ''
    })
  }
  // 虎牙视频相关
  async openVideo(req:OpenVideoReq, adapter:ExtAdapter):Promise<void> {
    window.open('https://v.huya.com/play/' + req.vid + '.html')
    return Promise.resolve()
  }
  // 播放器相关
  async switchLine(req:SwitchLineReq, adapter:ExtAdapter):Promise<void> {
    const hyExtAdapter = adapter as HyExtAdapter
    if (req.line) {
      const lines = await hyExtAdapter.getLinesInfo()
      if (lines.map(l => String(l.line)).indexOf(String(req.line)) >= 0) {
        return hyExtAdapter.switchLine(req.line)
      } else {
        return Promise.reject(new ExtParamInvalidError('线路' + req.line + '不存在'))
      }
    } else {
      return Promise.reject(new ExtParamInvalidError('必须输入线路'))
    }
  }
  async getLinesInfo(_:any, adapter:ExtAdapter):Promise<GetLinesInfoRsp> {
    return {
      lines: await (adapter as HyExtAdapter).getLinesInfo()
    }
  }
  async getCurrentLine(_:any, adapter:ExtAdapter):Promise<GetCurrentLineRsp> {
    return {
      line: await (adapter as HyExtAdapter).getCurrentLine()
    }
  }
  async playVideo(req:PlayVideoReq, adapter:ExtAdapter):Promise<void> {
    if (req && req.url) {
      return (adapter as HyExtAdapter).playVideo(req.url, req.startTime || 0)
    } else {
      return Promise.reject(new ExtParamInvalidError('必须提供url参数'))
    }
  }
  async getVideoInfo(_:any, adapter:ExtAdapter):Promise<GetVideoInfoRsp> {
    return {
      videoInfo: await (adapter as HyExtAdapter).getVideoInfo()
    }
  }
  async fastForward(req:FastForwardReq, adapter:ExtAdapter):Promise<void> {
    if (req && !isNaN(req.time)) {
      return (adapter as HyExtAdapter).fastForward(req.time)
    } else {
      return Promise.reject(new ExtParamInvalidError('必须提供time参数'))
    }
  }
  async fastBackward(req:FastBackwardReq, adapter:ExtAdapter):Promise<void> {
    if (req && !isNaN(req.time)) {
      return (adapter as HyExtAdapter).fastBackward(req.time)
    } else {
      return Promise.reject(new ExtParamInvalidError('必须提供time参数'))
    }
  }
  async resumeLive(_:any, adapter:ExtAdapter):Promise<void> {
    return (adapter as HyExtAdapter).resumeLive()
  }
  async setViewport(req:SetViewportReq, adapter:ExtAdapter):Promise<void> {
    return (adapter as HyExtAdapter).setViewport(req.x, req.y, req.scale)
  }
  async getViewport(_:any, adapter:ExtAdapter):Promise<GetViewportRsp> {
    return (adapter as HyExtAdapter).getViewport()
  }
  async addViewport(req:AddViewportReq, adapter:ExtAdapter):Promise<void> {
    return (adapter as HyExtAdapter).addViewport(req.x, req.y, req.scale)
  }
  async tapped(_:any, adapter:ExtAdapter):Promise<void> {
    return (adapter as HyExtAdapter).tapped()
  }
  async doubleTapped(_:any, adapter:ExtAdapter):Promise<void> {
    return (adapter as HyExtAdapter).doubleTapped()
  }
  async getVideoPosition(_:any, adapter:ExtAdapter):Promise<GetVideoPositionRsp> {
    return (adapter as HyExtAdapter).getVideoPosition()
  }
  async getFrameData(_:any, adapter:ExtAdapter):Promise<GetFrameDataRsp> {
    return (adapter as HyExtAdapter).getFrameData()
  }
  async playLive(req:PlayLiveReq, adapter:ExtAdapter):Promise<void> {
    if (req && req.streamName) {
      return (adapter as HyExtAdapter).playLive(req.streamName)
    } else {
      return Promise.reject(new ExtParamInvalidError('必须提供流名称参数'))
    }
  }
  async contextMenu(_:any, adapter:ExtAdapter):Promise<void> {
    return (adapter as HyExtAdapter).contextMenu()
  }
  async getVideoState(_:any, adapter:ExtAdapter):Promise<GetVideoStateRsp> {
    return (adapter as HyExtAdapter).getVideoState()
  }
  // 面板相关
  // 秩序相关
  // OB相关
  async getMatchInfo(_:any, adapter:ExtAdapter):Promise<GetMatchInfoRsp> {
    return (adapter as HyExtAdapter).getMatchInfo()
  }
  async getPerspectiveList(_:any, adapter:ExtAdapter):Promise<GetPerspectiveListRsp> {
    return (adapter as HyExtAdapter).getPerspectiveList()
  }
  async getCurrentPerspective(_:any, adapter:ExtAdapter):Promise<GetCurrentPerspectiveRsp> {
    return (adapter as HyExtAdapter).getCurrentPerspective()
  }
  async setPerspective(req:SetPerspectiveReq, adapter:ExtAdapter):Promise<void> {
    return (adapter as HyExtAdapter).setPerspective(req.pid)
  }
  async setSplitedOBStream(req:SetSplitedOBStreamReq, adapter:ExtAdapter):Promise<void> {
    return (adapter as HyExtAdapter).setSplitedOBStream(req.row, req.column, req.width, req.height)
  }
  // 后台相关
  // 视图相关
  // PK相关
  // 连麦相关
  // websocket相关
}