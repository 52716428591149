"use strict";
exports.__esModule = true;
exports.MAX_IMG_SIZE = exports.getExtVersionTag = exports.HB_INTERVAL = exports.PostMessageTopic = exports.isDev = exports.isNeedAuth = exports.isPublished = exports.DevMessageType = exports.ExtAuthType = exports.ExtVersionType = exports.ShowFlag = void 0;
var ShowFlag;
(function (ShowFlag) {
    ShowFlag[ShowFlag["ENTRANCE"] = 1] = "ENTRANCE";
    ShowFlag[ShowFlag["PANEL"] = 2] = "PANEL";
})(ShowFlag = exports.ShowFlag || (exports.ShowFlag = {}));
var ExtVersionType;
(function (ExtVersionType) {
    ExtVersionType[ExtVersionType["DEV"] = 1] = "DEV";
    ExtVersionType[ExtVersionType["TEST"] = 2] = "TEST";
    ExtVersionType[ExtVersionType["RELEASE"] = 3] = "RELEASE";
})(ExtVersionType = exports.ExtVersionType || (exports.ExtVersionType = {}));
var ExtAuthType;
(function (ExtAuthType) {
    ExtAuthType[ExtAuthType["NEED_AUTH"] = 1] = "NEED_AUTH";
    ExtAuthType[ExtAuthType["NO_NEED_AUTH"] = 2] = "NO_NEED_AUTH";
})(ExtAuthType = exports.ExtAuthType || (exports.ExtAuthType = {}));
var DevMessageType;
(function (DevMessageType) {
    DevMessageType["DEV_SYS"] = "dev-sys";
    DevMessageType["DEV_EXT"] = "dev-ext";
})(DevMessageType = exports.DevMessageType || (exports.DevMessageType = {}));
var isPublished = function (extMain) {
    return extMain.extVersionType === Number(ExtVersionType.RELEASE);
};
exports.isPublished = isPublished;
var isNeedAuth = function (extEndPoint) {
    return extEndPoint.typeAuthority.isNeedAuth !== ExtAuthType.NO_NEED_AUTH;
};
exports.isNeedAuth = isNeedAuth;
var isDev = function (extMain) {
    return extMain.extVersionType === Number(ExtVersionType.DEV);
};
exports.isDev = isDev;
var PostMessageTopic;
(function (PostMessageTopic) {
    PostMessageTopic["CONTEXT"] = "context.emit";
    PostMessageTopic["OBSERVER"] = "observer.emit";
    PostMessageTopic["LIFE_LOAD"] = "life.load";
    PostMessageTopic["LIFE_ENTER_FOREGROUND"] = "life.enterForeground";
    PostMessageTopic["LIFE_APPEAR"] = "life.appear";
    PostMessageTopic["LIFE_LEAVE_FOREGROUND"] = "life.leaveForeground";
    PostMessageTopic["LIFE_DISAPPEAR"] = "life.disappear";
})(PostMessageTopic = exports.PostMessageTopic || (exports.PostMessageTopic = {}));
exports.HB_INTERVAL = 60000;
var getExtVersionTag = function (extMain, extComEndpoint) {
    // extUuid&extId&extVersionType&extVersionId&extVersion(小程序uuid&小程序id&小程序版本类型&小程序版本id&小程序版本号
    return extMain.extUuid + "&" + 0 + "&" + extMain.extVersionType + "&" + extMain.extVersionId + "&" + extMain.extVersion;
};
exports.getExtVersionTag = getExtVersionTag;
exports.MAX_IMG_SIZE = 6 * 1024 * 1024;
