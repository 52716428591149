"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
var ExtError_1 = require("../modules/ExtError");
/**
 * 小程序endpoint模块管理器
 */
var ExtEndpointModulesManager = /** @class */ (function () {
    function ExtEndpointModulesManager() {
        /**
         * 小程序endpoint模块实现的集合（支持lazy-load）
         */
        this.extEndpointDelayModuleMap = {};
        /**
         * 小程序endpoint模块实例列表
         */
        this.extEndpointModuleList = [];
    }
    /**
     * 注册小程序endpoint模块实现
     * @param extType 小程序类型
     * @param DelayModule 模块实现
     */
    ExtEndpointModulesManager.prototype.registryExtEndpointModule = function (extType, DelayModule) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.extEndpointDelayModuleMap[extType] = DelayModule;
                return [2 /*return*/];
            });
        });
    };
    /**
     * 创建小程序endpoint模块的工厂方法
     * @param extMain 小程序主体信息
     * @param extComEndpoint 小程序endpoint信息
     * @param adapter 小程序适配器
     */
    ExtEndpointModulesManager.prototype.getExtEndpointModuleInstance = function (extMain, extComEndpoint, adapter) {
        return __awaiter(this, void 0, void 0, function () {
            var DelayModule;
            return __generator(this, function (_a) {
                DelayModule = this.extEndpointDelayModuleMap[extComEndpoint.extType];
                if (!!DelayModule) {
                    return [2 /*return*/, DelayModule.then(function (delayModule) {
                            var DelayExtEndpointModule = delayModule["default"];
                            return DelayExtEndpointModule.getInstance(extMain, extComEndpoint, adapter);
                        })];
                }
                else {
                    throw new ExtError_1.ExtNotSupportEndpointError(extComEndpoint);
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * 根据小程序主体信息列表渲染所有小程序endpoint模块
     * @param extMainList 小程序主体信息列表
     * @param adapter 小程序适配器
     */
    ExtEndpointModulesManager.prototype.renderAll = function (extMainList, adapter) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.extEndpointModuleList = [];
                        return [4 /*yield*/, Promise.all(extMainList.map(function (extMain) {
                                return Promise.all(extMain.extVersionDetail.extEndpoints.value.map(function (extEndpoint) {
                                    return _this.getExtEndpointModuleInstance(extMain, extEndpoint, adapter).then(function (extEndPointModule) {
                                        _this.extEndpointModuleList.push(extEndPointModule);
                                    });
                                }));
                            }))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, Promise.resolve(this.extEndpointModuleList)];
                }
            });
        });
    };
    /**
     * 获取小程序endpoint模块列表的汇总信息（extUuid、extName、extType）
     */
    ExtEndpointModulesManager.prototype.getSummary = function () {
        return this.extEndpointModuleList.map(function (e) { return ({
            extUuid: e.extMain.extUuid,
            extName: e.extMain.extName,
            extType: e.extEndpoint.extType
        }); });
    };
    /**
     * 获取列表中的某个小程序endpoint模块
     * @param extMain 小程序主体信息
     * @param extEndpoint 小程序endpoint信息
     */
    ExtEndpointModulesManager.prototype.getExtEndpointModule = function (extMain, extEndpoint) {
        return this.extEndpointModuleList.find(function (extEndpointModule) {
            return extEndpointModule.extMain.extUuid === extMain.extUuid &&
                extEndpointModule.extEndpoint.extType === extEndpoint.extType;
        });
    };
    /**
     * 获取列表中某个小程序的所有小程序endpoint模块
     * @param extUuid 小程序uuid
     */
    ExtEndpointModulesManager.prototype.getExtEndpointModuleListWithExtUuid = function (extUuid) {
        return this.extEndpointModuleList.filter(function (extEndpointModule) { return extEndpointModule.extMain.extUuid === extUuid; });
    };
    /**
     * 根据消息来源获取某个小程序endpoint模块
     * @param source 消息来源
     */
    ExtEndpointModulesManager.prototype.getExtEndpointModuleBySource = function (source) {
        for (var i = 0; i < this.extEndpointModuleList.length; i++) {
            if (source === this.extEndpointModuleList[i].extFrame.iframe.contentWindow) {
                return this.extEndpointModuleList[i];
            }
        }
        return null;
    };
    return ExtEndpointModulesManager;
}());
exports["default"] = ExtEndpointModulesManager;
