"use strict";
exports.__esModule = true;
var events_1 = require("events");
var ExtEndpointModuleBus = /** @class */ (function () {
    function ExtEndpointModuleBus() {
        this.busMap = new Map();
    }
    ExtEndpointModuleBus.prototype.getBus = function (busType) {
        if (!this.busMap.has(busType)) {
            this.busMap.set(busType, new events_1.EventEmitter());
        }
        return this.busMap.get(busType);
    };
    ExtEndpointModuleBus.prototype.on = function (busType, eventName, handler, only) {
        if (only === void 0) { only = false; }
        var bus = this.getBus(busType);
        if (only) {
            bus.removeAllListeners();
        }
        bus.on(eventName, handler);
    };
    ExtEndpointModuleBus.prototype.off = function (busType, eventName) {
        var bus = this.getBus(busType);
        bus.removeAllListeners(eventName);
    };
    return ExtEndpointModuleBus;
}());
exports["default"] = ExtEndpointModuleBus;
