const getYa = () => {
  return new Promise((resolve, reject) => {
    let c = 0
    const h = () => {
      if (typeof window.ya !== 'undefined') {
        resolve(window.ya)
      } else {
        c += 1
        if (c <= (process.env.NODE_ENV === 'development' ? 10 : 60)) {
          setTimeout(h, 1000)
        } else {
          window.ya = {
            // reportProductEvent: process.env.NODE_ENV === 'development'
            //   ? param => console.info('ya.reportProductEvent', param)
            //   : () => {}
            reportProductEvent: options => {
              if (options.eid !== 'sys/ext_heartbeat/ext/interactive') {
                console.info('ya.reportProductEvent', options.eid, JSON.parse(options.prop).ext_id, JSON.parse(options.prop).ext_type)
              }
            }
          }
          resolve(window.ya)
        }
      }
    }
    h()
  })
}

const getProp = (extMain:MidExtQuery.ExtMain, extEndpoint:MidExtQuery.ExtComEndpoint, auid:string, uid:number, gameId:number, isOn:boolean, liveCount:number, fans:number) => {
  return {
    auid,
    uid: String(uid),
    type: 'kiwi_web',
    ext_id: extMain.extUuid,
    game_id: String(gameId),
    live_status: isOn ? 'active' : 'offline',
    ext_type: extEndpoint.extType,
    ext_version: extMain.extVersionDetail.extVersion,
    ext_version_id: extMain.extVersionDetail.extVersionId,
    version_type: extMain.extVersionDetail.extVersionType,
    live_viewer_count: String(liveCount),
    subscribe_count: String(fans)
  }
}

// 新版埋点
const sys_pageshow_ext_list = (extMain:MidExtQuery.ExtMain, extEndpoint:MidExtQuery.ExtComEndpoint, auid:string, uid:number, gameId:number, isOn:boolean, liveCount:number, fans:number) => {
  const req = {
    eid: 'sys/pageshow/ext_list',
    eid_desc: '直播页面预览/虎牙小程序',
    prop: JSON.stringify(getProp(extMain, extEndpoint, auid, uid, gameId, isOn, liveCount, fans))
  }
  getYa().then(() => ya.reportProductEvent(req))
}

const sys_startup_ext_interactive = (extMain:MidExtQuery.ExtMain, extEndpoint:MidExtQuery.ExtComEndpoint, auid:string, uid:number, gameId:number, isOn:boolean, liveCount:number, fans:number) => {
  const req = {
    eid: 'sys/startup/ext/interactive',
    eid_desc: '用户点击互动通道/小程序',
    prop: JSON.stringify(getProp(extMain, extEndpoint, auid, uid, gameId, isOn, liveCount, fans))
  }
  getYa().then(() => ya.reportProductEvent(req))
}

const sys_ext_heartbeat_ext_interactive = (extMain:MidExtQuery.ExtMain, extEndpoint:MidExtQuery.ExtComEndpoint, auid:string, uid:number, gameId:number, sessionid:string, isOn:boolean, liveCount:number, fans:number) => {
  const req = {
    eid: 'sys/ext_heartbeat/ext/interactive',
    eid_desc: '心跳',
    prop: JSON.stringify({
      ...getProp(extMain, extEndpoint, auid, uid, gameId, isOn, liveCount, fans),
      sessionid
    })
  }
  getYa().then(() => ya.reportProductEvent(req))
}

const sys_pageshow_ext_panelshow = (extMain:MidExtQuery.ExtMain, extEndpoint:MidExtQuery.ExtComEndpoint, auid:string, uid:number, gameId:number, sessionid:string, isOn:boolean, liveCount:number, fans:number) => {
  const req = {
    eid: 'sys/pageshow/ext/panelshow',
    eid_desc: '浮窗面板显示',
    prop: JSON.stringify({
      ...getProp(extMain, extEndpoint, auid, uid, gameId, isOn, liveCount, fans),
      sessionid
    })
  }
  getYa().then(() => ya.reportProductEvent(req))
}

export const reportProductEvent = (eid:string, eid_desc:string, prop:string) => {
  return getYa().then(() => ya.reportProductEvent({
    eid, eid_desc, prop
  }))
}

export default ({
  sys_pageshow_ext_list,
  sys_startup_ext_interactive,
  sys_ext_heartbeat_ext_interactive,
  sys_pageshow_ext_panelshow
})
