"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.ExtParamInvalidError = exports.ExtCancelLoginError = exports.ExtInteractingError = exports.ExtRejectAuthError = exports.ExtInvalidOutputError = exports.ExtNotSupportError = exports.ExtApiAuthError = exports.ExtNotSupportEndpointError = exports.ExtInternalError = exports.ExtNotSupportPostMessageError = exports.ExtNotSupportSandboxError = exports.ExtErrorCodes = void 0;
var ExtErrorCodes;
(function (ExtErrorCodes) {
    ExtErrorCodes[ExtErrorCodes["NOT_SUPPORT_SANDBOX"] = 9999] = "NOT_SUPPORT_SANDBOX";
    ExtErrorCodes[ExtErrorCodes["NOT_SUPPORT_CSP"] = 9998] = "NOT_SUPPORT_CSP";
    ExtErrorCodes[ExtErrorCodes["NOT_SUPPORT_POSTMESSAGE"] = 9997] = "NOT_SUPPORT_POSTMESSAGE";
    ExtErrorCodes[ExtErrorCodes["NOT_SUPPORT_H5PLAYER"] = 9995] = "NOT_SUPPORT_H5PLAYER";
    ExtErrorCodes[ExtErrorCodes["INTERNAL_ERROR"] = 9994] = "INTERNAL_ERROR";
    ExtErrorCodes[ExtErrorCodes["NOT_SUPPORT_EXT_ENDPOINT"] = 9993] = "NOT_SUPPORT_EXT_ENDPOINT";
    ExtErrorCodes[ExtErrorCodes["CANCEL_LOGIN"] = 9001] = "CANCEL_LOGIN";
    ExtErrorCodes[ExtErrorCodes["NOT_SUPPORT"] = 9005] = "NOT_SUPPORT";
    ExtErrorCodes[ExtErrorCodes["API_AUTH_ERROR"] = 9015] = "API_AUTH_ERROR";
    ExtErrorCodes[ExtErrorCodes["INVALID_OUTPUT"] = 9016] = "INVALID_OUTPUT";
    ExtErrorCodes[ExtErrorCodes["REJECT_AUTH"] = 9002] = "REJECT_AUTH";
    ExtErrorCodes[ExtErrorCodes["INTERACTING"] = 9007] = "INTERACTING";
    ExtErrorCodes[ExtErrorCodes["PARAM_INVALID"] = 9009] = "PARAM_INVALID";
})(ExtErrorCodes = exports.ExtErrorCodes || (exports.ExtErrorCodes = {}));
/**
 * 小程序错误类
 */
var ExtError = /** @class */ (function (_super) {
    __extends(ExtError, _super);
    function ExtError(code, msg) {
        var _this = _super.call(this, JSON.stringify({ code: code, msg: msg })) || this;
        _this.code = code;
        return _this;
    }
    return ExtError;
}(Error));
var ExtNotSupportSandboxError = /** @class */ (function (_super) {
    __extends(ExtNotSupportSandboxError, _super);
    function ExtNotSupportSandboxError() {
        return _super.call(this, ExtErrorCodes.NOT_SUPPORT_SANDBOX, '当前浏览器不支持Sandbox') || this;
    }
    return ExtNotSupportSandboxError;
}(ExtError));
exports.ExtNotSupportSandboxError = ExtNotSupportSandboxError;
var ExtNotSupportPostMessageError = /** @class */ (function (_super) {
    __extends(ExtNotSupportPostMessageError, _super);
    function ExtNotSupportPostMessageError() {
        return _super.call(this, ExtErrorCodes.NOT_SUPPORT_SANDBOX, '当前浏览器不支持postMessage') || this;
    }
    return ExtNotSupportPostMessageError;
}(ExtError));
exports.ExtNotSupportPostMessageError = ExtNotSupportPostMessageError;
var ExtInternalError = /** @class */ (function (_super) {
    __extends(ExtInternalError, _super);
    function ExtInternalError(msg) {
        return _super.call(this, ExtErrorCodes.INTERNAL_ERROR, msg || '内部错误') || this;
    }
    return ExtInternalError;
}(ExtError));
exports.ExtInternalError = ExtInternalError;
var ExtNotSupportEndpointError = /** @class */ (function (_super) {
    __extends(ExtNotSupportEndpointError, _super);
    function ExtNotSupportEndpointError(extComEndpoint) {
        return _super.call(this, ExtErrorCodes.NOT_SUPPORT_EXT_ENDPOINT, "\u5F53\u524D\u5BBF\u4E3B\u4E0D\u652F\u6301\u5C0F\u7A0B\u5E8F\u7C7B\u578B" + extComEndpoint.extType) || this;
    }
    return ExtNotSupportEndpointError;
}(ExtError));
exports.ExtNotSupportEndpointError = ExtNotSupportEndpointError;
var ExtApiAuthError = /** @class */ (function (_super) {
    __extends(ExtApiAuthError, _super);
    function ExtApiAuthError(msg) {
        return _super.call(this, ExtErrorCodes.API_AUTH_ERROR, msg || '无调用权限') || this;
    }
    return ExtApiAuthError;
}(ExtError));
exports.ExtApiAuthError = ExtApiAuthError;
var ExtNotSupportError = /** @class */ (function (_super) {
    __extends(ExtNotSupportError, _super);
    function ExtNotSupportError(msg) {
        return _super.call(this, ExtErrorCodes.NOT_SUPPORT, msg || '小程序不支持这个操作') || this;
    }
    return ExtNotSupportError;
}(ExtError));
exports.ExtNotSupportError = ExtNotSupportError;
var ExtInvalidOutputError = /** @class */ (function (_super) {
    __extends(ExtInvalidOutputError, _super);
    function ExtInvalidOutputError(msg) {
        return _super.call(this, ExtErrorCodes.INVALID_OUTPUT, msg || '非法输出') || this;
    }
    return ExtInvalidOutputError;
}(ExtError));
exports.ExtInvalidOutputError = ExtInvalidOutputError;
var ExtRejectAuthError = /** @class */ (function (_super) {
    __extends(ExtRejectAuthError, _super);
    function ExtRejectAuthError(msg) {
        return _super.call(this, ExtErrorCodes.REJECT_AUTH, msg || '用户拒绝授权') || this;
    }
    return ExtRejectAuthError;
}(ExtError));
exports.ExtRejectAuthError = ExtRejectAuthError;
var ExtInteractingError = /** @class */ (function (_super) {
    __extends(ExtInteractingError, _super);
    function ExtInteractingError(msg) {
        return _super.call(this, ExtErrorCodes.INTERACTING, msg || '用户交互没有完成，请稍后') || this;
    }
    return ExtInteractingError;
}(ExtError));
exports.ExtInteractingError = ExtInteractingError;
var ExtCancelLoginError = /** @class */ (function (_super) {
    __extends(ExtCancelLoginError, _super);
    function ExtCancelLoginError() {
        return _super.call(this, ExtErrorCodes.CANCEL_LOGIN, '用户取消登录') || this;
    }
    return ExtCancelLoginError;
}(ExtError));
exports.ExtCancelLoginError = ExtCancelLoginError;
var ExtParamInvalidError = /** @class */ (function (_super) {
    __extends(ExtParamInvalidError, _super);
    function ExtParamInvalidError(msg) {
        return _super.call(this, ExtErrorCodes.CANCEL_LOGIN, msg || '参数错误') || this;
    }
    return ExtParamInvalidError;
}(ExtError));
exports.ExtParamInvalidError = ExtParamInvalidError;
exports["default"] = ExtError;
