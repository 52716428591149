"use strict";
exports.__esModule = true;
exports.Topic = void 0;
var Topic;
(function (Topic) {
    Topic["SUP_LOAD"] = "sup.load";
    Topic["SUP_UNLOAD"] = "sup.unload";
    Topic["SUP_READY"] = "sup.ready";
    Topic["SUP_INIT"] = "sup.init";
    Topic["FRAME_LOAD"] = "frame.load";
    Topic["SUP_ERROR"] = "sup.err";
})(Topic = exports.Topic || (exports.Topic = {}));
/**
 * 小程序消息
 */
var ExtFrameMessage = /** @class */ (function () {
    function ExtFrameMessage(data) {
        var reqId = data.reqId, topic = data.topic, message = data.message;
        this.originData = data;
        this.reqId = reqId;
        this.topic = topic || '';
        this.reqTopic = topic;
        this.funcName = this.topic.indexOf('.req') < 0 ? '' : this.topic.replace('.req', '');
        this.rspTopic = this.funcName ? this.funcName + '.rsp' : '';
        this.errTopic = this.funcName ? this.funcName + '.err' : '';
        this.message = message;
    }
    return ExtFrameMessage;
}());
exports["default"] = ExtFrameMessage;
