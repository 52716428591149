"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
var ext_1 = require("./ext");
var getProfileExtList = function (adapter) { return __awaiter(void 0, void 0, void 0, function () {
    var MidExtQuery, req, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, adapter.getJCE('MidExtQuery')];
            case 1:
                MidExtQuery = _b.sent();
                req = new MidExtQuery.GetProfileExtListReq();
                _a = req;
                return [4 /*yield*/, adapter.getUserId()];
            case 2:
                _a.tId = _b.sent();
                req.uid = adapter.getStreamerUid();
                req.gameType = adapter.getGameType();
                req.gameId = adapter.getGameId();
                req.iScreenType = adapter.getScreenType();
                req.iSourceType = adapter.getSourceType();
                req.request.sHostId = adapter.getHostId();
                return [2 /*return*/, adapter.getProfileExtList(req)
                        .then(function (rsp) { return Promise.resolve(rsp.extMainList.value); })["catch"](function (_) { return Promise.resolve([]); })];
        }
    });
}); };
var getUserExtAuthorizeInfo = function (adapter, extMain) { return __awaiter(void 0, void 0, void 0, function () {
    var MidExtAuth, req, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, adapter.getJCE('MidExtAuth')];
            case 1:
                MidExtAuth = _b.sent();
                req = new MidExtAuth.GetUserExtAuthorizeReq();
                _a = req;
                return [4 /*yield*/, adapter.getUserId()];
            case 2:
                _a.tId = _b.sent();
                req.extUuids.value.push(extMain.extUuid);
                return [2 /*return*/, adapter.getUserExtAuthorizeInfo(req)
                        .then(function (rsp) { return Promise.resolve(rsp.userExtAuthorizeMap.value[extMain.extUuid] === 1); })];
        }
    });
}); };
var userExtAuthorize = function (adapter, extMain) { return __awaiter(void 0, void 0, void 0, function () {
    var MidExtAuth, req, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, adapter.getJCE('MidExtAuth')];
            case 1:
                MidExtAuth = _b.sent();
                req = new MidExtAuth.UserExtAuthorizeReq();
                _a = req;
                return [4 /*yield*/, adapter.getUserId()];
            case 2:
                _a.tId = _b.sent();
                req.extUuid = extMain.extUuid;
                req.isAuthorize = 1;
                return [2 /*return*/, adapter.userExtAuthorize(req)];
        }
    });
}); };
var getJWT = function (adapter, extMain) { return __awaiter(void 0, void 0, void 0, function () {
    var MidExtAuth, req, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, adapter.getJCE('MidExtAuth')];
            case 1:
                MidExtAuth = _b.sent();
                req = new MidExtAuth.GetJWTReq();
                _a = req;
                return [4 /*yield*/, adapter.getUserId()];
            case 2:
                _a.tId = _b.sent();
                req.appId = extMain.authorAppId;
                req.extUuid = extMain.extUuid;
                req.pid = adapter.getStreamerUid();
                req.roomId = parseInt(adapter.getRoomId(), 10);
                return [2 /*return*/, adapter.getJWT(req)];
        }
    });
}); };
var routeEbsRequestV2 = function (adapter, extMain, extEndpoint, jwt, header, url, method, data) { return __awaiter(void 0, void 0, void 0, function () {
    var MidExtProxyRoute, req, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, adapter.getJCE('MidExtProxyRoute')];
            case 1:
                MidExtProxyRoute = _b.sent();
                req = new MidExtProxyRoute.RouteEBSV2Req();
                _a = req;
                return [4 /*yield*/, adapter.getUserId()];
            case 2:
                _a.tId = _b.sent();
                req.appId = extMain.authorAppId;
                req.extUuid = extMain.extUuid;
                req.extVersion = extMain.extVersion;
                req.uid = adapter.getStreamerUid();
                req.jwt = jwt;
                if (req.request) {
                    req.request.jwt = jwt;
                    req.request.sHostId = adapter.getHostId();
                    req.request.componentTag = extEndpoint.extType;
                    req.request.extVersionTag = ext_1.getExtVersionTag(extMain, extEndpoint);
                }
                if (header) {
                    Object.keys(header).forEach(function (k) {
                        req.ebsRequest.header.value[String(k)] = String(header[k]);
                    });
                }
                req.ebsRequest.url = url;
                req.ebsRequest.httpMethod = method;
                req.ebsRequest.bodyString = JSON.stringify(data);
                return [2 /*return*/, adapter.routeEbsRequestV2(req)];
        }
    });
}); };
var getStorageKey = function (adapter, extMain, extComEndpoint, key, jwt) { return __awaiter(void 0, void 0, void 0, function () {
    var MidExtCapability, req, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, adapter.getJCE('MidExtCapability')];
            case 1:
                MidExtCapability = _b.sent();
                req = new MidExtCapability.GetStorageKeyReq();
                _a = req;
                return [4 /*yield*/, adapter.getUserId()];
            case 2:
                _a.tId = _b.sent();
                req.request.componentTag = extComEndpoint.extType;
                req.request.extVersionTag = ext_1.getExtVersionTag(extMain, extComEndpoint);
                req.request.jwt = jwt;
                req.request.sHostId = adapter.getHostId();
                req.appId = extMain.authorAppId;
                req.dataKey = key;
                req.extUuid = extMain.extUuid;
                req.pid = adapter.getStreamerUid();
                return [2 /*return*/, adapter.getStorageKey(req)];
        }
    });
}); };
var setStorage = function (adapter, extMain, extComEndpoint, key, value, jwt) { return __awaiter(void 0, void 0, void 0, function () {
    var MidExtCapability, req, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, adapter.getJCE('MidExtCapability')];
            case 1:
                MidExtCapability = _b.sent();
                req = new MidExtCapability.SetStorageReq();
                req.appId = extMain.authorAppId;
                req.dataKey = key;
                req.dataValue = value;
                req.extUuid = extMain.extUuid;
                req.pid = adapter.getStreamerUid();
                req.request.componentTag = extComEndpoint.extType;
                req.request.extVersionTag = ext_1.getExtVersionTag(extMain, extComEndpoint);
                req.request.jwt = jwt;
                req.request.sHostId = adapter.getHostId();
                _a = req;
                return [4 /*yield*/, adapter.getUserId()];
            case 2:
                _a.tId = _b.sent();
                return [2 /*return*/, adapter.setStorage(req)];
        }
    });
}); };
var getStorageAllKeys = function (adapter, extMain, extComEndpoint, jwt) { return __awaiter(void 0, void 0, void 0, function () {
    var MidExtCapability, req, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, adapter.getJCE('MidExtCapability')];
            case 1:
                MidExtCapability = _b.sent();
                req = new MidExtCapability.GetStorageAllKeysReq();
                req.appId = extMain.authorAppId;
                req.extUuid = extMain.extUuid;
                req.pid = adapter.getStreamerUid();
                req.request.componentTag = extComEndpoint.extType;
                req.request.extVersionTag = ext_1.getExtVersionTag(extMain, extComEndpoint);
                req.request.jwt = jwt;
                req.request.sHostId = adapter.getHostId();
                _a = req;
                return [4 /*yield*/, adapter.getUserId()];
            case 2:
                _a.tId = _b.sent();
                return [2 /*return*/, adapter.getStorageAllKeys(req)];
        }
    });
}); };
var delStorageKey = function (adapter, extMain, extComEndpoint, key, jwt) { return __awaiter(void 0, void 0, void 0, function () {
    var MidExtCapability, req, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, adapter.getJCE('MidExtCapability')];
            case 1:
                MidExtCapability = _b.sent();
                req = new MidExtCapability.DelStorageKeyReq();
                _a = req;
                return [4 /*yield*/, adapter.getUserId()];
            case 2:
                _a.tId = _b.sent();
                req.request.componentTag = extComEndpoint.extType;
                req.request.extVersionTag = ext_1.getExtVersionTag(extMain, extComEndpoint);
                req.request.jwt = jwt;
                req.request.sHostId = adapter.getHostId();
                req.appId = extMain.authorAppId;
                req.dataKey = key;
                req.extUuid = extMain.extUuid;
                req.pid = adapter.getStreamerUid();
                return [2 /*return*/, adapter.delStorageKey(req)];
        }
    });
}); };
var getPicUploadPolicy = function (adapter, extMain, extComEndpoint, jwt) { return __awaiter(void 0, void 0, void 0, function () {
    var MidExtCapability, req, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, adapter.getJCE('MidExtCapability')];
            case 1:
                MidExtCapability = _b.sent();
                req = new MidExtCapability.GetPicUploadPolicyReq();
                req.pid = adapter.getStreamerUid();
                req.request.componentTag = extComEndpoint.extType;
                req.request.extVersionTag = ext_1.getExtVersionTag(extMain, extComEndpoint);
                req.request.jwt = jwt;
                req.request.sHostId = adapter.getHostId();
                req.roomId = Number(adapter.getRoomId());
                _a = req;
                return [4 /*yield*/, adapter.getUserId()];
            case 2:
                _a.tId = _b.sent();
                req.appId = extMain.authorAppId;
                req.extUuid = extMain.extUuid;
                return [2 /*return*/, adapter.getPicUploadPolicy(req)];
        }
    });
}); };
var textReportSDKFormExt = function (adapter, extMain, text) { return __awaiter(void 0, void 0, void 0, function () {
    var MidExtInspection, req, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, adapter.getJCE('MidExtInspection')];
            case 1:
                MidExtInspection = _b.sent();
                req = new MidExtInspection.TextReportSDKFormExtReq();
                req.extUuid = extMain.extUuid;
                req.pid = adapter.getStreamerUid();
                _a = req;
                return [4 /*yield*/, adapter.getUserId()];
            case 2:
                _a.tId = _b.sent();
                req.appId = extMain.authorAppId;
                req.content = text;
                return [2 /*return*/, adapter.textReportSDKFormExt(req)];
        }
    });
}); };
var commonBusiness = function (adapter, extMain, extComEndpoint, functionType, paramJson, jwt) { return __awaiter(void 0, void 0, void 0, function () {
    var MidExtCommonQuery, req, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, adapter.getJCE('MidExtCommonQuery')];
            case 1:
                MidExtCommonQuery = _b.sent();
                req = new MidExtCommonQuery.CommonQueryReq();
                req.appId = extMain.authorAppId;
                req.extUuid = extMain.extUuid;
                req.functionType = functionType;
                req.paramJson = paramJson;
                req.pid = adapter.getStreamerUid();
                req.request.extVersionTag = ext_1.getExtVersionTag(extMain, extComEndpoint);
                req.request.jwt = jwt;
                req.request.sHostId = adapter.getHostId();
                req.request.componentTag = extComEndpoint.extType;
                _a = req;
                return [4 /*yield*/, adapter.getUserId()];
            case 2:
                _a.tId = _b.sent();
                return [2 /*return*/, adapter.commonBusiness(req)];
        }
    });
}); };
exports["default"] = ({
    getProfileExtList: getProfileExtList,
    getUserExtAuthorizeInfo: getUserExtAuthorizeInfo,
    userExtAuthorize: userExtAuthorize,
    getJWT: getJWT,
    routeEbsRequestV2: routeEbsRequestV2,
    getStorageKey: getStorageKey,
    setStorage: setStorage,
    getStorageAllKeys: getStorageAllKeys,
    delStorageKey: delStorageKey,
    getPicUploadPolicy: getPicUploadPolicy,
    textReportSDKFormExt: textReportSDKFormExt,
    commonBusiness: commonBusiness
});
