"use strict";
exports.__esModule = true;
exports.trim = exports.unescapeHTML = exports.trimHttp = void 0;
var trimHttp = function (url) {
    return url.replace('http://', 'https://');
};
exports.trimHttp = trimHttp;
var unescapeHTML = function (str) { return str.replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
    .replace(/&#39;/g, "'"); };
exports.unescapeHTML = unescapeHTML;
var trim = function (text, len) {
    if (text.length < len) {
        return text;
    }
    else {
        return text.slice(0, len) + '...';
    }
};
exports.trim = trim;
