"use strict";
exports.__esModule = true;
exports.ensureBrowserSupportPostMessage = exports.ensureBrowserSupportSandbox = exports.ensureBrowserSupport = void 0;
var ExtError_1 = require("../modules/ExtError");
var ensureBrowserSupport = function () {
    return Promise.all([
        exports.ensureBrowserSupportSandbox(),
        exports.ensureBrowserSupportPostMessage()
    ]);
};
exports.ensureBrowserSupport = ensureBrowserSupport;
var ensureBrowserSupportSandbox = function () {
    if ('sandbox' in document.createElement('iframe')) {
        return Promise.resolve();
    }
    else {
        return Promise.reject(new ExtError_1.ExtNotSupportSandboxError());
    }
};
exports.ensureBrowserSupportSandbox = ensureBrowserSupportSandbox;
var ensureBrowserSupportPostMessage = function () {
    if ('postMessage' in window) {
        return Promise.resolve();
    }
    else {
        return Promise.reject(new ExtError_1.ExtNotSupportPostMessageError());
    }
};
exports.ensureBrowserSupportPostMessage = ensureBrowserSupportPostMessage;
