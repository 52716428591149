import { ExtCancelLoginError, ExtError, ExtFunctionalProvider, ExtInternalError, ExtNotSupportError, trim, unescapeHTML } from "@hyext-inner/ext-web-comp";
import { InnerExtAdapter } from '@hyext-inner/inner-ext-web-comp'
import { MAX_TOAST_LENGTH, NORMAL_TIMEOUT, SUP_HOST, SUP_INNER_HOST, WUP_TIMEOUT } from '../config/const'
import { HyExtType, TTPGetFuncName } from "../util/hy";
import { BusType, EventName, funcMap, HyTafFuncName, HyTafUI, HyTafURI, TTCallFuncName, TTPCallFuncName, TTPEventName } from "../util/hy";
import { barrageJceToBarrageInfo, giftJceToGiftInfo, guardianPresenterInfoNoticeToOpenGuardianInfo, nobleLevelNoticeToOpenNobleInfo, nobleNoticeToOpenNobleInfo, vipEnterBannerToVipEnterInfo } from "../util/jce";
import { promiseTimeout } from "../util/promise";
import ya from "../util/ya";
import { showAuthConfirmModal, showUnpublishedConfirmModal } from "../views/HyExtModal";
import HyExtEndpointModule from "./ext/HyExtEndpointModule";
import HyExtFunctionalProvider from "./HyExtFunctionalProvider";

let gReqId:number = 100000

export default class HyExtAdapter extends InnerExtAdapter implements HyExtCompInitOptions {
  TTA:TTA
  TTP:TTP
  TT:TT
  TTR:TTR
  roomFollow:RoomFollow
  widgetDialog:WidgetDialog
  liveInfo:HyLiveInfo
  constructor(options:HyExtCompInitOptions) {
    super()
    this.TTA = options.TTA
    this.TTP = options.TTP
    this.TT = options.TT
    this.TTR = options.TTR
    this.roomFollow = options.roomFollow
    this.widgetDialog = options.widgetDialog
    this.liveInfo = {
      gameName: this.TTR.gameFullName,
      liveCount: Number(this.TTR.totalCount),
      roomTitle: this.TTR.introduction,
      startTime: Number(this.TTR.startTime),
      isOn: this.TTR.isOn
    }
  }
  // 业务方法
  getPlayer():Promise<HyPlayer> {
    return new Promise(resolve => {
      this.TTP.ready(player => {
        resolve(player)
      })
    }) 
  }
  ttpCall(funcName:TTPCallFuncName, param:any) {
    const ret = this.TTP.call(funcName, param)
    if (funcName !== TTPCallFuncName.EXT_FEED_BACK) {
      this.logger.debug('TTP.call', { funcName, param, ret })
    }
    return ret
  }
  ttpGet(funcName:TTPGetFuncName) {
    const ret = this.TTP.get(funcName)
    this.logger.debug('TTP.get', { funcName, ret })
    return ret
  }
  ttpCallAsync(funcName:TTPCallFuncName, param:any):Promise<any> {
    const r = (ret:{res:number, msg:string, data:any}) => {
      this.logger.debug('TTP.call2', { funcName, param, ret })
      if (ret) {
        if (ret.res === 0) {
          return Promise.resolve(ret.data)
        } else {
          return Promise.reject(new ExtError(ret.res, ret.msg))
        }
      } else {
        return Promise.reject(new ExtError(9999, '未知错误'))
      }
    }
    const ret = this.ttpCall(funcName, param)
    if (ret && typeof ret.then === 'function') {
      return ret.then(r).catch((err:any) => {
        if (err instanceof Error) {
          return Promise.reject(err)
        } else {
          return r(err)
        }
      })
    } else {
      return r(ret)
    }
  }
  ttCall(funcName:TTCallFuncName, param:any) {
    const ret = this.TT.call(String(funcName), param)
    this.logger.debug('TT.call', { funcName, param, ret })
    return ret
  }
  async addTafListener(uri:string, handler:Function) {
    const player = await this.getPlayer()
    player.addTafListener(uri, handler)
  }
  async sendWup2(ui:HyTafUI, funcName:HyTafFuncName, req:any):Promise<any> {
    const player = await this.getPlayer()
    const reqId = gReqId + 1
    req.tId = player.userId
    this.logger.debug('发送taf请求', { ui, funcName, req, reqId })
    return promiseTimeout(
      WUP_TIMEOUT,
      `调用${funcName}超时`,
      new Promise((resolve, reject) => player.sendWup2(ui, funcName, req, rsp => {
        if (rsp.iRequestId === reqId) {
          const { response } = rsp
          if (response) {
            const { res, msg } = response
            if (res === 0) {
              this.logger.debug('发送taf请求成功', { ui, funcName, req, reqId, rsp })
              resolve(rsp)
            } else {
              this.logger.warn('发送taf请求失败', { ui, funcName, req, reqId, res, msg })
              reject(new ExtError(res, msg))
            }
          } else {
            this.logger.warn('发送taf请求失败，返回结构错误', { ui, funcName, req, reqId, rsp })
            reject(new ExtInternalError())
          }
        } else {
          this.logger.warn('发送taf请求失败，iRequestId对不上', { ui, funcName, req, reqId, rsp })
          reject(new ExtInternalError())
        }
      }, null, reqId))
    )
  }
  onWsMessage(callback: (wsMessage:WsMessage) => void) {
    this.TTP.on(TTPEventName.WS_MESSAGE, callback)
  }
  onExtTafData(callback: (uri:number, funcName:string, data:ArrayBuffer) => void) {
    this.TTP.on(TTPEventName.EXT_TAF_DATA, (notice:{ uri:number, funcName:string, data:ArrayBuffer }) => {
      callback(notice.uri, notice.funcName, notice.data)
    })
    return Promise.resolve()
  }
  onMatchStatusChange(callback: (notice: any) => void) {
    this.TTP.on(TTPEventName.MATCH_STATUS_CHANGE, callback)
  }
  onPerspectiveChange(callback: (notice: any) => void) {
    this.TTP.on(TTPEventName.PERSPECTIVE_CHANGE, callback)
  }
  onObMatchEnd(callback: (notice: any) => void) {
    this.TTP.on(TTPEventName.OB_MATCH_END, callback)
  }
  onObMatchBegin(callback: (notice: any) => void) {
    this.TTP.on(TTPEventName.OB_MATCH_BEGIN, callback)
  }
  onVideoFrameReduceEnd(callback: (notice: any) => void) {
    this.TTP.on(TTPEventName.VIDEO_FRAME_REDUCE_END, callback)
  }
  onVideoFrameReduceStart(callback: (notice: any) => void) {
    this.TTP.on(TTPEventName.VIDEO_FRAME_REDUCE_START, callback)
  }
  onViewportChange(callback: (notice: any) => void) {
    this.TTP.on(TTPEventName.VIEW_PORT_CHANGE, callback)
  }
  onEndLive(callback: () => void) {
    this.TTP.on(TTPEventName.END_LIVE, callback)
  }
  onBeginLive(callback: () => void) {
    this.TTP.on(TTPEventName.BEGIN_LIVE, callback)
  }
  onExtVideoEvent(callback:(type:string) => void) {
    this.TTP.on(TTPEventName.EXT_VIDEO_EVENT, callback)
  }
  onClickEntrance(callback:(message:{ id:string }) => void) {
    this.TTP.on(TTPEventName.CLICK_DIY_ICON, callback)
  }
  addDiyIcon(req:AddDiyIconReq) {
    return this.ttpCall(TTPCallFuncName.ADD_DIY_ICON, req)
  }
  delDiyIcon(req:DelDiyIconReq) {
    return this.ttpCall(TTPCallFuncName.DEL_DIY_ICON, req)
  }
  getHyUserInfo():Promise<UserInfo> {
    return promiseTimeout<UserInfo>(
      NORMAL_TIMEOUT,
      '获取用户信息超时',
      new Promise<UserInfo>(resolve => {
        this.TT.getUserInfo(userInfo => resolve(userInfo))
      })
    )
  }
  async getHyUserLevel():Promise<GetUserLevelResp> {
    return new Promise<GetUserLevelResp>((resolve, reject) => {
      $.ajax({
        url: 'https://www.huya.com/member/task.php?m=User&do=listTotal&callback=?',
        dataType: 'jsonp',
        success: (json:any) => {
          if (json &&
            json.data &&
            json.data.level &&
            json.data.level.userLevel) {
            resolve({
              userLevel: json.data.level.userLevel
            })
          } else {
            resolve({
              userLevel: 0
            })
          }
        },
        error: () => {
          resolve({
            userLevel: 0
          })
        }
      })
    })
  }
  chatroomPanelExtSwitch(options:{switch:boolean}):void {
    this.ttCall(TTCallFuncName.CHAT_ROOM_PANEL_EXT_SWITCH, { switch: true })
  }
  getExtContainerUnderBarrage():any {
    return this.ttpCall(TTPCallFuncName.GET_EXT_CONTAINER_UNDERBARRAGE, {})
  }
  getExtPopupContainer():any {
    return this.ttpCall(TTPCallFuncName.GET_EXT_POPUP_CONTAINER, {})
  }
  onPageFullScreen(handler:() => void) {
    this.TTP.on(TTPEventName.PAGE_FULLSCREEN, () => handler())
  }
  // 覆盖方法
  getFunctionalProvider(extMain:MidExtQuery.ExtMain, extComEndpoint:MidExtQuery.ExtComEndpoint, modalContainer:any):ExtFunctionalProvider {
    return HyExtFunctionalProvider.getSharedInstance(this.logger, extMain, extComEndpoint, modalContainer)
  }
  getFuncMap() {
    return funcMap
  }
  async registryExtEndpointModule():Promise<void> {
    await this.extEndpointModulesManager.registryExtEndpointModule(
      HyExtType.WEB_VIDEO_COM,
      import('./ext/HyExtWebVideoComEndpointModule' /* webpackChunkName: "web-video-comp" */)
    )
    await this.extEndpointModulesManager.registryExtEndpointModule(
      HyExtType.WEB_POPUP,
      import('./ext/HyExtWebPopupEndpointModule' /* webpackChunkName: "web-popup" */)
    )
  }
  async subscribe():Promise<void> {
    const { lUid } = await this.getUserId()
    if (lUid) {
      return this.roomFollow.on()
    } else {
      return this.login()
    }
  }
  async leadGift():Promise<void> {
    const { lUid } = await this.getUserId()
    if (lUid) {
      return this.ttpCall(TTPCallFuncName.SHOW_GIFT_TIP, {
        msg: '点这里给主播送礼'
      })
    } else {
      return this.login()
    }
  }
  async getGiftData():Promise<Array<HyGiftItem>> {
    return Promise.resolve(this.ttpGet(TTPGetFuncName.GET_GIFT_DATA))
  }
  async sendGift(giftId:number, giftCount:number):Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.TTP.on(TTPEventName.SEND_GIFT_EXT_RSP, (rsp:{code:number, msg:string}) => {
        const { code, msg } = rsp
        if (code === 0) {
          resolve()
        } else {
          reject(new ExtInternalError(msg))
        }
      })
      this.ttpCall(TTPCallFuncName.SEND_GIFT_EXT, {
        propsId: giftId,
        num: giftCount
      })
    })
  }
  async leadBarrage():Promise<void> {
    const { lUid } = await this.getUserId()
    if (lUid) {
      this.TT.event.emit('showBarrageTip', {
        msg: '点这里进行发言'
      })
    } else {
      return this.login()
    }
  }
  async setSendBarrageTag(tag:string):Promise<void> {
    this.ttpCall(TTPCallFuncName.SET_SEND_BARRAGE_TAG, tag)
    return Promise.resolve()
  }
  async setDisplayBarrageTags(tags:Array<string>):Promise<void> {
    this.ttpCall(TTPCallFuncName.SET_DISPLAY_BARRAGE_TAGS, { tags })
  }
  async showDiyBadge(id:string, value:0|1):Promise<void> {
    this.ttpCall(TTPCallFuncName.SHOW_DIY_BADGE, { id, value })
    return Promise.resolve()
  }
  async showToast(msg:string):Promise<void> {
    this.widgetDialog.toast(unescapeHTML(trim(msg, MAX_TOAST_LENGTH)))
  }
  async getWeekRank():Promise<HyWeekRank> {
    return new Promise<HyWeekRank>((resolve, reject) => {
      this.ttCall(TTCallFuncName.GET_WEEK_RANK, {}).then(resolve, (err:any) => reject(new ExtInternalError(err.message)))
    })
  }
  async getFansRank():Promise<HyFansRank> {
    return new Promise<HyFansRank>((resolve, reject) => {
      this.ttCall(TTCallFuncName.GET_FANS_RANK, {}).then(resolve, (err:any) => reject(new ExtInternalError(err.message)))
    })
  }
  async getVip():Promise<HyVipInfo> {
    return new Promise<HyVipInfo>((resolve, reject) => {
      this.ttCall(TTCallFuncName.GET_VIP, {}).then(resolve, (err:any) => reject(new ExtInternalError(err.message)))
    })
  }
  async getStreamerLevel():Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.ttCall(TTCallFuncName.GET_STREAMER_LEVEL, {}).then(resolve, (err:any) => reject(new ExtInternalError(err.message)))
    })
  }
  async getUserLevelByUid(uid:number):Promise<number> {
    // TODO
    return Promise.resolve(0)
  }
  async getViewerLatencyMode():Promise<{mode:number}> {
    return this.ttpCallAsync(TTPCallFuncName.GET_VIEWER_LATENCY_MODE, {})
  }
  async setViewerLatencyMode(req:{mode:number}):Promise<void> {
    return this.ttpCallAsync(TTPCallFuncName.SET_VIEWER_LATENCY_MODE, req)
  }
  async getTaf():Promise<any> {
    const player = await this.getPlayer()
    return player.taf.Taf
  }
  async getHUYA():Promise<any> {
    const player = await this.getPlayer()
    return player.taf.HUYA
  }
  sendExtWup(buff:ArrayBuffer, funcName:string, servant:string):Promise<void> {
    this.ttpCall(TTPCallFuncName.SEND_EXT_WUP, {
      buff, funcName, servant
    })
    return Promise.resolve()
  }
  setExtUri(uri:number):Promise<void> {
    this.ttpCall(TTPCallFuncName.SET_EXT_URI, uri)
    return Promise.resolve()
  }
  openUrl(url:string):Promise<void> {
    return this.ttpCallAsync(TTPCallFuncName.OPEN_URL, { url })
  }
  getJoinMicUserList():Promise<any> {
    return this.ttpCallAsync(TTPCallFuncName.GET_JOIN_MIC_USER_LIST, {})
  }
  sendGiftToUser(uid:number, giftId:number, giftCount:number):Promise<{res:number, msg:string}> {
    return this.ttpCall(TTPCallFuncName.SEND_GIFT_TO_USER, { uid, giftId, giftCount })
  }
  async switchLine(line:number):Promise<void> {
    return this.ttpCall(TTPCallFuncName.SWITCH_LINE, { line })
  }
  getLinesInfo():Promise<Array<{line:number, label:string}>> {
    return Promise.resolve(this.ttpCall(TTPCallFuncName.GET_LINES_INFO, {}))
  }
  getCurrentLine():Promise<number> {
    return Promise.resolve(this.ttpCall(TTPCallFuncName.GET_CURRENT_LINE, {}))
  }
  async playVideo(url:string, startTime:number):Promise<void> {
    return this.ttpCall(TTPCallFuncName.PLAY_VIDEO, { url, startTime })
  }
  async getVideoInfo():Promise<{duration:number, currentTime:number, isPlaying:boolean}> {
    return this.ttpCall(TTPCallFuncName.GET_VIDEO_INFO, {})
  }
  async fastForward(time:number):Promise<void> {
    return this.ttpCall(TTPCallFuncName.FAST_FORWARD, { time })
  }
  async fastBackward(time:number):Promise<void> {
    return this.ttpCall(TTPCallFuncName.FAST_BACKWARD, { time })
  }
  async resumeLive():Promise<void> {
    return this.ttpCall(TTPCallFuncName.RESUME_LIVE, {})
  }
  async setViewport(x:number, y:number, scale:number):Promise<void> {
    const ret:number = this.ttpCall(TTPCallFuncName.SET_VIEW_PORT, { x, y, scale })
    if (ret === 0) {
      return Promise.resolve()
    } else {
      return Promise.reject(new ExtInternalError('设置失败'))
    }
  }
  async getViewport():Promise<{x:number, y:number, scale:number}> {
    const ret = this.ttpCall(TTPCallFuncName.GET_VIEW_PORT, {})
    if (ret === -1) {
      return Promise.reject(new ExtInternalError('获取失败'))
    } else {
      return Promise.resolve(ret)
    }
  }
  async addViewport(x:number, y:number, scale:number):Promise<void> {
    const ret:number = this.ttpCall(TTPCallFuncName.ADD_VIEW_PORT, { x, y, scale })
    if (ret === 0) {
      return Promise.resolve()
    } else {
      return Promise.reject(new ExtInternalError('设置失败'))
    }
  }
  async tapped():Promise<void> {
    const ret:number = this.ttpCall(TTPCallFuncName.TAPPED, {})
    if (ret === 0) {
      return Promise.resolve()
    } else {
      return Promise.reject(new ExtInternalError('操作失败'))
    }
  }
  async doubleTapped():Promise<void> {
    const ret:number = this.ttpCall(TTPCallFuncName.DOUBLE_TAPPED, {})
    if (ret === 0) {
      return Promise.resolve()
    } else {
      return Promise.reject(new ExtInternalError('操作失败'))
    }
  }
  async getVideoPosition():Promise<{left:number, top:number, width:number, bottom:number}> {
    return this.ttpCall(TTPCallFuncName.GET_VIDEO_POSITION, {})
  }
  async getFrameData():Promise<{pts:number}> {
    return this.ttpCall(TTPCallFuncName.GET_FRAME_DATA, {})
  }
  async playLive(streamName:string):Promise<void> {
    return this.ttpCallAsync(TTPCallFuncName.PLAY_LIVE, { streamName })
  }
  async contextMenu():Promise<void> {
    const ret:number = this.ttpCall(TTPCallFuncName.CONTEXT_MENU, {})
    if (ret === 0) {
      return Promise.resolve()
    } else {
      return Promise.reject(new ExtInternalError('操作失败'))
    }
  }
  async getVideoState():Promise<{state:number}> {
    return this.ttpCall(TTPCallFuncName.GET_VIDEO_STATE, {})
  }
  async getMatchInfo():Promise<{state:number}> {
    return this.ttpCall(TTPCallFuncName.GET_MATCH_INFO, {})
  }
  async getPerspectiveList():Promise<Array<{pid:number, name:string}>> {
    return this.ttpCall(TTPCallFuncName.GET_PERSPECTIVE_LIST, {})
  }
  async getCurrentPerspective():Promise<{pid:number, name:string}> {
    return this.ttpCall(TTPCallFuncName.GET_CURRENT_PERSPECTIVE, {})
  }
  async setPerspective(pid:number):Promise<void> {
    const ret:number = this.ttpCall(TTPCallFuncName.SET_PERSPECTIVE, { pid })
    if (ret === 0) {
      return Promise.resolve()
    } else {
      return Promise.reject(new ExtInternalError('操作失败'))
    }
  }
  async setSplitedOBStream(row:number, column:number, width:number, height:number):Promise<void> {
    const ret:number = this.ttpCall(TTPCallFuncName.SET_SPLITED_OB_STREAM, {
      row, column, width, height
    })
    if (ret === 0) {
      return Promise.resolve()
    } else {
      return Promise.reject(new ExtInternalError('操作失败'))
    }
  }
  async wsConnect(url:string):Promise<{wsId:string}> {
    return this.ttpCallAsync(TTPCallFuncName.WS_CONNECT, { url })
  }
  async wsSend(wsId:string, message:any):Promise<void> {
    return this.ttpCallAsync(TTPCallFuncName.WS_SEND, { wsId, message })
  }
  async wsClose(wsId:string, code:number, reason:string):Promise<void> {
    return this.ttpCallAsync(TTPCallFuncName.WS_CLOSE, { wsId, code, reason })
  }
  // mark 抽象方法
  async registerGroupByGroupName(group:string):Promise<void> {
    const player = await this.getPlayer()
    return player.registerGroup(group)
  }
  playerReady(): Promise<void> {
    return new Promise(resolve => {
      this.TTP.ready(() => {
        resolve()
      })
    })
  }
  ensureSupport(): Promise<void> {
    if (typeof $ === 'function' && typeof $.ajax === 'function') {
      return Promise.resolve()
    } else {
      return Promise.reject(new ExtNotSupportError('虎牙小程序需要全局jQuery支持'))
    }
  }
  async handleMessage(): Promise<void> {
    await super.handleMessage()
    this.addTafListener(HyTafURI.kSecPackVipEnterBanner, async (data:HUYA.VipEnterBanner) => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach(async (hyExtEndpointModule:HyExtEndpointModule) => {
        const vipEnterInfo = await vipEnterBannerToVipEnterInfo(data, hyExtEndpointModule.extMain, this)
        hyExtEndpointModule.bus.getBus(BusType.VIP).emit(EventName.VIP_ENTER_BANNER_NOTICE, vipEnterInfo)
      })
    })
    this.addTafListener(HyTafURI.kSecPackTypeGuardianPresenterInfoNotice, async (data:HUYA.GuardianPresenterInfoNotice) => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach(async (hyExtEndpointModule:HyExtEndpointModule) => {
        const openGuardianInfo = await guardianPresenterInfoNoticeToOpenGuardianInfo(data, hyExtEndpointModule.extMain, this)
        hyExtEndpointModule.bus.getBus(BusType.GUARDIAN).emit(EventName.OPEN_GUARDIAN_NOTICE, openGuardianInfo)
      })
    })
    this.addTafListener(HyTafURI.kSecPackTypeNewNobleNotice, async (data:HUYA.NobleNotice) => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach(async (hyExtEndpointModule:HyExtEndpointModule) => {
        const openNobleInfo = await nobleNoticeToOpenNobleInfo(data, hyExtEndpointModule.extMain, this)
        hyExtEndpointModule.bus.getBus(BusType.NOBLE).emit(EventName.OPEN_NOBLE_NOTICE, openNobleInfo)
      })
    })
    this.addTafListener(HyTafURI.kSecPackTypeNewNobleLevelNotice, async (data:HUYA.NobleLevelNotice) => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach(async (hyExtEndpointModule:HyExtEndpointModule) => {
        const openNobleInfo = await nobleLevelNoticeToOpenNobleInfo(data, hyExtEndpointModule.extMain, this)
        hyExtEndpointModule.bus.getBus(BusType.NOBLE).emit(EventName.OPEN_NOBLE_NOTICE, openNobleInfo)
      })
    })
    this.addTafListener(HyTafURI.kSecPackTypeBeginLiveNotice, async (data:HUYA.BeginLiveNotice) => {
      this.liveInfo = {
        ...this.liveInfo,
        gameName: data.sGameName,
        liveCount: data.lAttendeeCount,
        roomTitle: data.sLiveDesc,
        startTime: data.lStartTime,
        isOn: true
      }
    })
    this.addTafListener(HyTafURI.kSecPackTypeEndLiveNotice, async (data:HUYA.EndLiveNotice) => {
      this.liveInfo = {
        ...this.liveInfo,
        isOn: false
      }
    })
    this.addTafListener(HyTafURI.kSecPackTypeAttendeeCountNotice, async (data:HUYA.AttendeeCountNotice) => {
      this.liveInfo = {
        ...this.liveInfo,
        liveCount: data.iAttendeeCount
      }
    })
    this.addTafListener(HyTafURI.kSecPackTypeItemConsumSubNotify, async (data:HUYA.SendItemSubBroadcastPacket) => {
      const userId = await this.getUserId()
      this.extEndpointModulesManager.extEndpointModuleList.forEach(async (hyExtEndpointModule:HyExtEndpointModule) => {
        const giftInfo = await giftJceToGiftInfo(data, hyExtEndpointModule.extMain, this)
        hyExtEndpointModule.bus.getBus(BusType.GIFT).emit(EventName.GIFT_CHANGE, giftInfo)
        if (String(data.lSenderUid) === String(userId.lUid)) {
          hyExtEndpointModule.postGiftSubmit(giftInfo, this)
        }
      })
    })
    this.addTafListener(HyTafURI.kSecPackTypeMessageNotice, async (data:HUYA.MessageNotice) => {
      const userId = await this.getUserId()
      this.extEndpointModulesManager.extEndpointModuleList.forEach(async (hyExtEndpointModule:HyExtEndpointModule) => {
        const barrageInfo = await barrageJceToBarrageInfo(data, hyExtEndpointModule.extMain, this)
        hyExtEndpointModule.bus.getBus(BusType.BARRAGE).emit(EventName.BARRAGE_CHANGE, barrageInfo)
        if (String(data.tUserInfo.lUid) === String(userId.lUid)) {
          hyExtEndpointModule.postBarrageSubmit(barrageInfo, this)
        }
      })
    })
    this.onExtVideoEvent((type:string) => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach((hyExtEndpointModule:HyExtEndpointModule) => {
        hyExtEndpointModule.postVideoEvent(type, this)
      })
    })
    this.onBeginLive(() => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach((hyExtEndpointModule:HyExtEndpointModule) => {
        hyExtEndpointModule.postBeginLive(this)
      })
    })
    this.onEndLive(() => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach((hyExtEndpointModule:HyExtEndpointModule) => {
        hyExtEndpointModule.postEndLive(this)
      })
    })
    this.onViewportChange((notice:any) => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach((hyExtEndpointModule:HyExtEndpointModule) => {
        hyExtEndpointModule.postViewportChange(notice, this)
      })
    })
    this.onVideoFrameReduceStart((notice:any) => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach((hyExtEndpointModule:HyExtEndpointModule) => {
        hyExtEndpointModule.postVideoFrameReduceStart(notice, this)
      })
    })
    this.onVideoFrameReduceEnd((notice:any) => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach((hyExtEndpointModule:HyExtEndpointModule) => {
        hyExtEndpointModule.postVideoFrameReduceEnd(notice, this)
      })
    })
    this.onObMatchBegin((notice:any) => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach((hyExtEndpointModule:HyExtEndpointModule) => {
        hyExtEndpointModule.postObMatchBegin(notice, this)
      })
    })
    this.onObMatchEnd((notice:any) => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach((hyExtEndpointModule:HyExtEndpointModule) => {
        hyExtEndpointModule.postObMatchEnd(notice, this)
      })
    })
    this.onPerspectiveChange((notice:any) => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach((hyExtEndpointModule:HyExtEndpointModule) => {
        hyExtEndpointModule.postPerspectiveChange(notice, this)
      })
    })
    this.onMatchStatusChange((notice:any) => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach((hyExtEndpointModule:HyExtEndpointModule) => {
        hyExtEndpointModule.postMatchStatusChange(notice, this)
      })
    })
    // 长链接模块
    this.onWsMessage(({ wsId, event, param }) => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach((hyExtEndpointModule:HyExtEndpointModule) => {
        const message = { wsId, event }
        switch (event) {
          case 'close':
            hyExtEndpointModule.extFrame.postMessageToBusiFrame('ws.' + event, { ...message, code: param.code, reason: param.reason }, this)
            break
          case 'error':
            hyExtEndpointModule.extFrame.postMessageToBusiFrame('ws.' + event, { ...message, message: param }, this)
            break
          case 'open':
            hyExtEndpointModule.extFrame.postMessageToBusiFrame('ws.' + event, message, this)
            break
          case 'message':
            hyExtEndpointModule.extFrame.postMessageToBusiFrame('ws.' + event, { ...message, data: param }, this)
            break
        }
      })
    })
    this.roomFollow.change(state => {
      this.extEndpointModulesManager.extEndpointModuleList.forEach((hyExtEndpointModule:HyExtEndpointModule) => {
        hyExtEndpointModule.postSubscribeChange(state, this)
      })
    })
  }
  isOriginValid(origin: string): Promise<boolean> {
    return Promise.resolve(
      origin.indexOf(SUP_HOST) >= 0 ||
      origin.indexOf(SUP_INNER_HOST) >= 0
    )
  }
  login(): Promise<void> {
    return new Promise((resolve, reject) => {
      const cancelHandler = () => {
        this.TT.login.off('loginClose', cancelHandler)
        reject(new ExtCancelLoginError())
      }
      this.TT.login.on('loginClose', cancelHandler)
      this.TT.login.login()
    })
  }
  async getJCE(moduleName: string):Promise<any> {
    const player = await this.getPlayer()
    switch (moduleName) {
      case 'MidExtQuery':
        return ({
          GetProfileExtListReq: player.taf.HUYA.GetProfileExtListReq
        })
      case 'MidExtAuth':
        return ({
          GetUserExtAuthorizeReq: player.taf.HUYA.GetUserExtAuthorizeReq,
          UserExtAuthorizeReq: player.taf.HUYA.UserExtAuthorizeReq,
          GetJWTReq: player.taf.HUYA.GetJWTReq
        })
      case 'MidExtProxyRoute':
        return ({
          RouteEBSV2Req: player.taf.HUYA.RouteEBSV2Req
        })
      case 'MidExtCapability':
        return ({
          GetStorageKeyReq: player.taf.HUYA.GetStorageKeyReq,
          SetStorageReq: player.taf.HUYA.SetStorageReq,
          GetStorageAllKeysReq: player.taf.HUYA.GetStorageAllKeysReq,
          DelStorageKeyReq: player.taf.HUYA.DelStorageKeyReq,
          GetPicUploadPolicyReq: player.taf.HUYA.GetPicUploadPolicyReq,
          DeliverRoomMsgByUnionIdReq: player.taf.HUYA.DeliverRoomMsgByUnionIdReq,
          GetCommonFileUploadPolicyReq: player.taf.HUYA.GetCommonFileUploadPolicyReq,
          ShowEntranceReq: player.taf.HUYA.ShowEntranceReq,
          CloseEntranceReq: player.taf.HUYA.CloseEntranceReq
        })
      case 'MidExtInspection':
        return ({
          TextReportSDKFormExtReq: player.taf.HUYA.TextReportSDKFormExtReq
        })
      case 'MidExtCommonQuery':
        return ({
          CommonQueryReq: player.taf.HUYA.CommonQueryReq
        })
      case 'MidExtCommonOperate':
        return ({
          CommonOperateReq: player.taf.ExtCommonOperate.CommonOperateReq
        })
    }
  }
  async getUserId():Promise<MidExtComm.UserId> {
    const player = await this.getPlayer()
    return player.userId
  }
  getStreamerUid(): number {
    return Number(this.TTA.lp)
  }
  getGameType(): number {
    return this.TTR.bussType
  }
  getGameId(): number {
    return this.TTR.gid
  }
  getScreenType(): number {
    return this.TTR.screenType
  }
  getSourceType(): number {
    return this.TTR.liveSourceType
  }
  getHostId(): string {
    return ''
  }
  getProfileExtList(req: MidExtQuery.GetProfileExtListReq): Promise<MidExtQuery.GetProfileExtListResp> {
    return this.sendWup2(HyTafUI.EXT_QUERY_UI, HyTafFuncName.GET_PROFILE_EXT_LIST, req)
  }
  getUserExtAuthorizeInfo(req: MidExtAuth.GetUserExtAuthorizeReq): Promise<MidExtAuth.GetUserExtAuthorizeResp> {
    return this.sendWup2(HyTafUI.EXT_AUTH_UI, HyTafFuncName.GET_USER_EXT_AUTHORIZE_INFO, req)
  }
  log(logContent: string): void {
    this.ttpCall(TTPCallFuncName.EXT_FEED_BACK, logContent)
  }
  getSupUrl(extMain: MidExtQuery.ExtMain): string {
    if (extMain.extAppCspPolicy.hostLevel === 3) {
      return `//${SUP_INNER_HOST}/hy-ext-sup/${process.env.SUP_VERSION}/index.html`
    } else {
      return `//${SUP_HOST}/hy-ext-sup/${process.env.SUP_VERSION}/index.html`
    }
  }
  getSupHost(extMain: MidExtQuery.ExtMain): string {
    if (extMain.extAppCspPolicy.hostLevel === 3) {
      return SUP_INNER_HOST
    } else {
      return SUP_HOST
    }
  }
  async registerGroup(extMain: MidExtQuery.ExtMain):Promise<void> {
    const player = await this.getPlayer()
    player.registerGroup(`hyext:${extMain.extUuid}`)
    player.registerGroup(`hyext:${extMain.extUuid}_${this.TTA.lp}`)
  }
  userExtAuthorize(req: MidExtAuth.UserExtAuthorizeReq): Promise<MidExtAuth.UserExtAuthorizeResp> {
    return this.sendWup2(HyTafUI.EXT_AUTH_UI, HyTafFuncName.USER_EXT_AUTHORIZE, req)
  }
  getRoomId(): string {
    return this.TTA.profileRoom
  }
  getJWT(req: MidExtAuth.GetJWTReq): Promise<MidExtAuth.GetJWTResp> {
    return this.sendWup2(HyTafUI.EXT_AUTH_UI, HyTafFuncName.GET_JWT, req)
  }
  routeEbsRequestV2(req: MidExtProxyRoute.RouteEBSV2Req): Promise<MidExtProxyRoute.RouteEBSV2Resp> {
    return this.sendWup2(HyTafUI.EXT_ROUTE_UI, HyTafFuncName.ROUTE_EBS_REQUEST_V2, req)
  }
  async sys_pageshow_ext_list(extMain: MidExtQuery.ExtMain, extComEndpoint: MidExtQuery.ExtComEndpoint) {
    const auid = this.TTA.lp
    const userId = await this.getUserId()
    const gameId = this.TTR.gid
    const isOn = this.liveInfo.isOn
    const liveCount = this.liveInfo.liveCount
    const fans = Number(this.TTA.fans)
    ya.sys_pageshow_ext_list(extMain, extComEndpoint, auid, Number(userId.lUid), gameId, isOn, liveCount, fans) 
  }
  async sys_startup_ext_interactive(extMain: MidExtQuery.ExtMain, extComEndpoint: MidExtQuery.ExtComEndpoint) {
    const auid = this.TTA.lp
    const userId = await this.getUserId()
    const gameId = this.TTR.gid
    const isOn = this.liveInfo.isOn
    const liveCount = this.liveInfo.liveCount
    const fans = Number(this.TTA.fans)
    ya.sys_startup_ext_interactive(extMain, extComEndpoint, auid, Number(userId.lUid), gameId, isOn, liveCount, fans) 
  }
  async sys_ext_heartbeat_ext_interactive(extMain: MidExtQuery.ExtMain, extComEndpoint: MidExtQuery.ExtComEndpoint, sessionId: string) {
    const auid = this.TTA.lp
    const userId = await this.getUserId()
    const gameId = this.TTR.gid
    const isOn = this.liveInfo.isOn
    const liveCount = this.liveInfo.liveCount
    const fans = Number(this.TTA.fans)
    ya.sys_ext_heartbeat_ext_interactive(extMain, extComEndpoint, auid, Number(userId.lUid), gameId, sessionId, isOn, liveCount, fans) 
  }
  async sys_pageshow_ext_panelshow(extMain: MidExtQuery.ExtMain, extComEndpoint: MidExtQuery.ExtComEndpoint, sessionId: string) {
    const auid = this.TTA.lp
    const userId = await this.getUserId()
    const gameId = this.TTR.gid
    const isOn = this.liveInfo.isOn
    const liveCount = this.liveInfo.liveCount
    const fans = Number(this.TTA.fans)
    ya.sys_pageshow_ext_panelshow(extMain, extComEndpoint, auid, Number(userId.lUid), gameId, sessionId, isOn, liveCount, fans) 
  }
  async showUnpublishedConfirmModal(extMain: MidExtQuery.ExtMain, extEndpoint: MidExtQuery.ExtComEndpoint, container: any): Promise<boolean> {
    return showUnpublishedConfirmModal({
      extMain: extMain,
      extEndpoint: extEndpoint,
      container
    }).then(() => Promise.resolve(true), _ => Promise.resolve(false))
  }
  requestWithoutProxy(jwt: string, header: { [key: string]: string; }, url: string, method: string, data: any, dataType: string): Promise<RequestRsp> {
    return new Promise<RequestRsp>((resolve, reject) => {
      $.ajax({
        headers: { ...header, Authorization: jwt },
        method,
        url,
        data: method.toLocaleLowerCase() === 'get'
          ? {}
          : JSON.stringify(data),
        contentType: method.toLocaleLowerCase() === 'get'
        ? ''
        : 'application/json',
        dataType
      }).then(entity => {
        resolve({ data: entity, statusCode: 200, header: {} })
      }, ({ statusCode }) => {
        // @ts-ignore
        resolve({ data: dataType === 'text' ? '' : {}, statusCode, header: {} })
      })
    })
  }
  showAuthConfirmModal(extMain: MidExtQuery.ExtMain, extEndpoint: MidExtQuery.ExtComEndpoint, container: any): Promise<void> {
    return showAuthConfirmModal({
      extMain: extMain,
      extEndpoint: extEndpoint,
      container
    })
  }
  addDevExtMessageSystemHandler(handler: (data: DEV.Message) => void): Promise<void> {
    return this.addTafListener(HyTafURI.kSecPackTypeDevExtMessageSystem, (data:DEV.Message) => handler(data))
  }
  addDevExtMessagePushHandler(handler: (data: DEV.Message) => void): Promise<void> {
    return this.addTafListener(HyTafURI.kSecPackTypeDevExtMessagePush, (data:DEV.Message) => handler(data))
  }
  getWasmScriptUrl(): string {
    return 'https://hyext.msstatic.com/wasm/genUnionId.js'
  }
  getStorageKey(req:MidExtCapability.GetStorageKeyReq):Promise<MidExtCapability.GetStorageKeyResp> {
    return this.sendWup2(HyTafUI.EXT_CAPABILITY_UI, HyTafFuncName.GET_STORAGE_KEY, req)
  }
  setStorage(req:MidExtCapability.SetStorageReq):Promise<MidExtCapability.SetStorageResp> {
    return this.sendWup2(HyTafUI.EXT_CAPABILITY_UI, HyTafFuncName.SET_STORAGE, req)
  }
  getStorageAllKeys(req:MidExtCapability.GetStorageAllKeysReq):Promise<MidExtCapability.GetStorageAllKeysResp> {
    return this.sendWup2(HyTafUI.EXT_CAPABILITY_UI, HyTafFuncName.GET_STORAGE_ALL_KEYS, req)
  }
  delStorageKey(req:MidExtCapability.DelStorageKeyReq):Promise<MidExtCapability.DelStorageKeyResp> {
    return this.sendWup2(HyTafUI.EXT_CAPABILITY_UI, HyTafFuncName.DEL_STORAGE_KEY, req)
  }
  getPicUploadPolicy(req:MidExtCapability.GetPicUploadPolicyReq):Promise<MidExtCapability.GetPicUploadPolicyResp> {
    return this.sendWup2(HyTafUI.EXT_CAPABILITY_UI, HyTafFuncName.GET_PIC_UPLOAD_POLICY, req)
  }
  textReportSDKFormExt(req: MidExtInspection.TextReportSDKFormExtReq): Promise<MidExtInspection.TextReportSDKFormExtResp> {
    return this.sendWup2(HyTafUI.EXT_INSPECTION_UI, HyTafFuncName.TEXT_REPORT_SDK_FORM_EXT, req)
  }
  commonBusiness(req:MidExtCommonQuery.CommonQueryReq):Promise<MidExtCommonQuery.CommonQueryResp> {
    return this.sendWup2(HyTafUI.EXT_COMMON_QUERY_UI, HyTafFuncName.COMMON_BUSINESS, req)
  }
  // TODO 暂时无用的接口
  getWebPanelContainer(extMain: MidExtQuery.ExtMain, extComEndpoint: MidExtQuery.ExtComEndpoint): Promise<any> {
    throw new Error("Method not implemented.");
  }
  getWebPanelEntranceContainer(extMain: MidExtQuery.ExtMain, extComEndpoint: MidExtQuery.ExtComEndpoint): Promise<any> {
    throw new Error("Method not implemented.");
  }
}