import { ExtComp, ExtLogger } from '@hyext-inner/ext-web-comp'
import HyExtAdapter from './modules/HyExtAdapter'

let sharedInstance:HyExtComp = null

export default class HyExtComp {
  extComp:ExtComp
  adapter:HyExtAdapter
  logger:ExtLogger
  static getSharedInstance () {
    if (sharedInstance) {
      return Promise.resolve(sharedInstance)
    } else {
      sharedInstance = new HyExtComp()
      return Promise.resolve(sharedInstance)
    }
  }
  async init (options:HyExtCompInitOptions) {
    this.extComp = new ExtComp()
    this.adapter = new HyExtAdapter(options)
    if (process.env.NODE_ENV === 'development') {
      // @ts-ignore
      window.extComp = this.extComp
      // @ts-ignore
      window.hyExtAdapter = this.adapter
    }
    this.logger = ExtLogger.getGlobalLogger(logContent => this.adapter.log(logContent))
    this.logger.info('欢迎使用虎牙小程序，努力初始化中...')
    try {
      this.logger.debug('中台小程序组件初始化')
      await this.extComp.init(this.adapter)
      this.logger.debug('拉取小程序列表')
      await this.extComp.fetchExtMainList()
      this.logger.debug('中台小程序组件初始化完成', { extMainList: this.extComp.extMainList })
      await this.adapter.extEndpointModulesManager.renderAll(this.extComp.extMainList, this.adapter)
      this.logger.debug('Endpoints初始化完毕', { hyEndpointList: this.adapter.extEndpointModulesManager.extEndpointModuleList })
      this.logger.info('虎牙小程序初始化完成，成功初始化小程序endpoint', { hyEndpointList: this.adapter.extEndpointModulesManager.getSummary() })
    } catch (err) {
      this.logger.warn('虎牙小程序初始化失败', { err: err.message })
      if (process.env.NODE_ENV === 'development') {
        throw err
      }
    }
  }
}
