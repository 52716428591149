import { ExtInteractingError, ExtRejectAuthError } from "@hyext-inner/ext-web-comp"

const style = require('@/sass/modal.scss')
const iconStyle = require('@/sass/icon.scss')

const showedMap:any = {}

export interface OnOkCallback {
  ():void
}

export interface OnCancelCallback {
  ():void
}

export interface ShowModalOptions {
  id:string
  container:any
  title:string
  content:string
  onOk:OnOkCallback
  onCancel:OnCancelCallback
  okText:string
  cancelText:string
  protocol:any
  type:any
}

export interface ShowConfirmModalOptions {
  id:string
  container:any
  title:string
  content:string
  okText:string
  cancelText:string
  protocol:any
  type:any
}

export interface ShowLeadModalOptions {
  extMain:MidExtQuery.ExtMain
  extEndpoint:MidExtQuery.ExtComEndpoint
  container:any
  onOk:OnOkCallback
  onCancel:OnCancelCallback
}

export interface ShowUnpublishedConfirmModalOptions {
  extMain:MidExtQuery.ExtMain
  extEndpoint:MidExtQuery.ExtComEndpoint
  container:any
}

export interface ShowAuthConfirmModalOptions {
  extMain:MidExtQuery.ExtMain
  extEndpoint:MidExtQuery.ExtComEndpoint
  container:any
}

export interface HySendGiftInfo {
  giftName:string
  giftLogo:string
  giftCount:number
}

export interface ShowSendGiftModalOptions {
  sendGiftInfo:HySendGiftInfo
  extMain:MidExtQuery.ExtMain
  extEndpoint:MidExtQuery.ExtComEndpoint
  container:any
}

const getId = (extMain:MidExtQuery.ExtMain, extEndpoint:MidExtQuery.ExtComEndpoint) => {
  return `${extMain.extUuid}_${extEndpoint.extType}_${extEndpoint.extEndpointId}`
}

export const showModal = (options:ShowModalOptions):Promise<void> => {
  const { id, container, title, content, protocol, type } = options
  if (showedMap[id]) {
    return Promise.reject(new ExtInteractingError())
  } else {
    const $container = $(container).length ? $(container) : $('body')
    const $content = $(content || '')
    const onOk = typeof options.onOk === 'function' ? options.onOk : () => {}
    const onCancel = typeof options.onCancel === 'function' ? options.onCancel : () => {}
    const okText = options.okText || '确定'
    const cancelText = options.cancelText || '取消'

    const close = () => {
      delete showedMap[id]
      $el.remove()
      $el = null
    }

    let $el = $(require('@/tpl/modal.html')({
      style,
      iconStyle,
      title,
      okText,
      cancelText,
      protocol,
      type
    }))
    $el.on('click', '.' + style.jqOk, () => {
      onOk()
      close()
    }).on('click', '.' + style.jqCancel, () => {
      onCancel()
      close()
    })

    $el.find('.' + style.jqContent).append($content)
    $container.append($el)
    showedMap[id] = true
  }
}

export const showConfirmModal = (options:ShowConfirmModalOptions):Promise<void> => {
  const { id, container, title, content, okText, cancelText, protocol, type } = options
  return new Promise((resolve, reject) => {
    showModal({
      id,
      container,
      title,
      content,
      okText,
      cancelText,
      onOk: resolve,
      onCancel: reject,
      protocol,
      type
    })
  })
}

export const showLeadSubscribeModal = (options:ShowLeadModalOptions):Promise<void> => {
  const { extMain, extEndpoint, container, onOk, onCancel } = options
  return showModal({
    id: getId(extMain, extEndpoint),
    container,
    title: '确认订阅',
    content: require('@/tpl/modal/lead-subscribe.html')({
      extName: extMain.extName,
      anchorName: extMain.extVersionDetail.extAuthorName
    }),
    onOk,
    onCancel,
    okText: '去订阅',
    cancelText: '再想想',
    protocol: null,
    type: null
  })
}

export const showLeadGiftModal = (options:ShowLeadModalOptions):Promise<void> => {
  const { extMain, extEndpoint, container, onOk, onCancel } = options
  return showModal({
    id: getId(extMain, extEndpoint),
    container,
    title: '确认送礼',
    content: require('@/tpl/modal/lead-gift.html')({
      extName: extMain.extName,
      anchorName: extMain.extVersionDetail.extAuthorName
    }),
    onOk,
    onCancel,
    okText: '去送礼',
    cancelText: '再想想',
    protocol: null,
    type: null
  })
}

export const showLeadBarrageModal = (options:ShowLeadModalOptions):Promise<void> => {
  const { extMain, extEndpoint, container, onOk, onCancel } = options
  return showModal({
    id: getId(extMain, extEndpoint),
    container,
    title: '确认发言',
    content: require('@/tpl/modal/lead-barrage.html')({
      extName: extMain.extName,
      anchorName: extMain.extVersionDetail.extAuthorName
    }),
    onOk,
    onCancel,
    okText: '去发言',
    cancelText: '再想想',
    protocol: null,
    type: null
  })
}

export const showUnpublishedConfirmModal = (options:ShowUnpublishedConfirmModalOptions):Promise<void> => {
  const { extMain, extEndpoint, container } = options
  return showConfirmModal({
    id: getId(extMain, extEndpoint),
    container,
    title: '提示',
    content: require('@/tpl/modal/unpublished-confirm.html')({
      extName: extMain.extName
    }),
    okText: '接受',
    cancelText: '拒绝',
    protocol: null,
    type: null
  })
}

export const showAuthConfirmModal = (options:ShowAuthConfirmModalOptions):Promise<void> => {
  const { extMain, extEndpoint, container } = options
  return showConfirmModal({
    id: getId(extMain, extEndpoint),
    container,
    title: '确认授权',
    content: require('@/tpl/modal/auth.html')({
      extName: extMain.extName,
      style,
      iconStyle
    }),
    okText: '允许',
    cancelText: '拒绝',
    protocol: {
      serviceUrl: 'https://cmsstatic.huya.com/huya_xiaochengxu/user.html',
      privateUrl: 'https://blog.huya.com/regulation/1811/406047305772.html'
    },
    type: null
  }).catch(_ => Promise.reject(new ExtRejectAuthError()))
}

export const showSendGiftModal = (options:ShowSendGiftModalOptions):Promise<void> => {
  const { container, extMain, extEndpoint, sendGiftInfo } = options
  return showConfirmModal({
    id: getId(extMain, extEndpoint),
    container,
    title: '',
    content: require('@/tpl/modal/send-gift.html')({
      extName: extMain.extName,
      gift: sendGiftInfo,
      style,
      iconStyle
    }),
    okText: '立即赠送',
    cancelText: '我再想想',
    protocol: null,
    type: style.sendGiftModal
  })
}
