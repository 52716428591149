export function promiseTimeout<T>(ms:number, msg:string, promise:Promise<T>):Promise<T> {
  let id:ReturnType<typeof setTimeout>

  // Create a promise that rejects in <ms> milliseconds
  const timeout = new Promise((resolve, reject) => {
    id = setTimeout(() => {
      reject(new Error(msg || 'Timed out in '+ ms + 'ms.'))
    }, ms)
  })

  // Returns a race between our timeout and the passed in promise
  return new Promise((resolve, reject) => {
    Promise.race([
      promise,
      timeout
    ]).then((res:T) => {
      clearTimeout(id)
      resolve(res)
    }).catch(reject)
  })
}

export function delay (ms:number):Promise<void> {
  return new Promise(resolve => {
    setTimeout(() => resolve(), ms)
  })
}