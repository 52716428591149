"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.BusType = void 0;
var ext_web_comp_1 = require("@hyext-inner/ext-web-comp");
var jce_1 = require("../../util/jce");
var gReqId = Math.floor(new Date().valueOf() / 100000);
var BusType;
(function (BusType) {
    BusType["TAF"] = "taf";
})(BusType = exports.BusType || (exports.BusType = {}));
var InnerExtEndpointModule = /** @class */ (function (_super) {
    __extends(InnerExtEndpointModule, _super);
    function InnerExtEndpointModule() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InnerExtEndpointModule.prototype.onWupMessage = function (req, adapter) {
        return __awaiter(this, void 0, void 0, function () {
            var innerExtAdapter;
            var _this = this;
            return __generator(this, function (_a) {
                innerExtAdapter = adapter;
                innerExtAdapter.setExtUri(Number(req.options.uri));
                this.bus.getBus(BusType.TAF).on(String(req.options.uri), function (msgBase64Str) {
                    _this.extFrame.postMessageToBusiFrame(req.target, { msgBase64Str: msgBase64Str }, adapter);
                });
                return [2 /*return*/];
            });
        });
    };
    InnerExtEndpointModule.prototype.sendWup = function (req, adapter) {
        return __awaiter(this, void 0, void 0, function () {
            var innerExtAdapter, Taf, HUYA, wup, reqId, cmd, os;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        innerExtAdapter = adapter;
                        return [4 /*yield*/, innerExtAdapter.getTaf()];
                    case 1:
                        Taf = _a.sent();
                        return [4 /*yield*/, innerExtAdapter.getHUYA()];
                    case 2:
                        HUYA = _a.sent();
                        wup = new Taf.Wup();
                        wup.setServant(req.servant);
                        wup.setFunc(req.funcName);
                        wup.writeStruct('tReq', {
                            writeTo: function (os) {
                                os.getBinBuffer().writeBytes(new Taf.BinBuffer(jce_1.base642Ab(req.reqBase64Str)));
                            }
                        });
                        reqId = gReqId++;
                        wup.setRequestId(reqId);
                        cmd = new HUYA.WebSocketCommand();
                        cmd.iCmdType = HUYA.EWebSocketCommandType.EWSCmd_WupReq;
                        cmd.vData = wup.encode();
                        os = new Taf.JceOutputStream();
                        cmd.writeTo(os);
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                innerExtAdapter.sendExtWup(os.getBuffer(), req.funcName, req.servant);
                                var handler = function (buffer) {
                                    _this.bus.getBus(BusType.TAF).off(req.funcName + '_' + reqId, handler);
                                    resolve({
                                        wupBase64Str: jce_1.ab2Base64(buffer)
                                    });
                                };
                                _this.bus.getBus(BusType.TAF).on(req.funcName + '_' + reqId, handler);
                            })];
                }
            });
        });
    };
    return InnerExtEndpointModule;
}(ext_web_comp_1.ExtEndpointModule));
exports["default"] = InnerExtEndpointModule;
