export enum TTPEventName {
  WS_MESSAGE = 'wsMessage',
  CLICK_DIY_ICON = 'clickDiyIcon',
  EXT_VIDEO_EVENT = 'extVideoEvent',
  MATCH_STATUS_CHANGE = 'matchStatusChange',
  PERSPECTIVE_CHANGE = 'perspectiveChange',
  OB_MATCH_END = 'obMatchEnd',
  OB_MATCH_BEGIN = 'obMatchBegin',
  VIDEO_FRAME_REDUCE_END = 'videoFrameReduceEnd',
  VIDEO_FRAME_REDUCE_START = 'videoFrameReduceStart',
  VIEW_PORT_CHANGE = 'viewportChange',
  END_LIVE = 'endLive',
  BEGIN_LIVE = 'beginLive',
  PAGE_FULLSCREEN = 'pageFullScreen',
  SEND_GIFT_EXT_RSP = 'sendGiftExtRsp',
  EXT_TAF_DATA = 'extTafData'
}

export enum TTPCallFuncName {
  EXT_FEED_BACK = 'extFeedBack',
  ADD_DIY_ICON = 'addDiyIcon',
  DEL_DIY_ICON = 'delDiyIcon',
  GET_EXT_CONTAINER_UNDERBARRAGE = 'getExtContainerUnderBarrage',
  GET_EXT_POPUP_CONTAINER = 'getExtPopupContainer',
  SHOW_GIFT_TIP = 'showGiftTip',
  SEND_GIFT_EXT = 'sendGiftExt',
  SET_SEND_BARRAGE_TAG = 'setSendBarrageTag',
  SET_DISPLAY_BARRAGE_TAGS = 'setDisplayBarrageTags',
  SHOW_DIY_BADGE = 'showDiyBadge',
  GET_VIEWER_LATENCY_MODE = 'getViewerLatencyMode',
  SET_VIEWER_LATENCY_MODE = 'setViewerLatencyMode',
  SEND_EXT_WUP = 'sendExtWup',
  SET_EXT_URI = 'setExtUri',
  OPEN_URL = 'openUrl',
  GET_JOIN_MIC_USER_LIST = 'getJoinMicUserList',
  SEND_GIFT_TO_USER = 'sendGiftToUser',
  GET_LINES_INFO = 'getLinesInfo',
  SWITCH_LINE = 'switchLine',
  GET_CURRENT_LINE = 'getCurrentLine',
  PLAY_VIDEO = 'playVideo',
  GET_VIDEO_INFO = 'getVideoInfo',
  FAST_FORWARD = 'fastForward',
  FAST_BACKWARD = 'fastBackward',
  RESUME_LIVE = 'resumeLive',
  SET_VIEW_PORT = 'setViewport',
  GET_VIEW_PORT = 'getViewport',
  ADD_VIEW_PORT = 'addViewport',
  TAPPED = 'tapped',
  DOUBLE_TAPPED = 'doubleTapped',
  GET_VIDEO_POSITION = 'getVideoPosition',
  GET_FRAME_DATA = 'getFrameData',
  PLAY_LIVE = 'playLive',
  CONTEXT_MENU = 'contextMenu',
  GET_VIDEO_STATE = 'getVideoState',
  GET_MATCH_INFO = 'getMatchInfo',
  GET_PERSPECTIVE_LIST = 'getPerspectiveList',
  GET_CURRENT_PERSPECTIVE = 'getCurrentPerspective',
  SET_PERSPECTIVE = 'setPerspective',
  SET_SPLITED_OB_STREAM = 'setSplitedOBStream',
  WS_CONNECT = 'wsConnect',
  WS_SEND = 'wsSend',
  WS_CLOSE = 'wsClose'
}

export enum TTPGetFuncName {
  GET_GIFT_DATA = 'getGiftData'
}

export enum TTCallFuncName {
  CHAT_ROOM_PANEL_EXT_SWITCH = 'chatroomPanelExtSwitch',
  GET_WEEK_RANK = 'getWeekRank',
  GET_FANS_RANK = 'getFansRank',
  GET_VIP = 'getVip',
  GET_STREAMER_LEVEL = 'getStreamerLevel'
}

export enum HyPostMessageTopic {
  PLAYER = 'player.emit',
  BARRAGE_SUBMIT = 'barrageSubmit',
  GIFT_SUBMIT = "giftSubmit",
  SUBSCRIBE_SUBMIT = 'subscribeSubmit'
}

export enum HyTafUI {
  EXT_QUERY_UI = 'midExtQueryUI',
  EXT_AUTH_UI = 'midExtAuthUI',
  EXT_ROUTE_UI = 'midExtProxyRouteUI',
  EXT_CAPABILITY_UI = 'midExtCapabilityUI',
  EXT_INSPECTION_UI = 'midExtInspectionUI',
  EXT_COMMON_QUERY_UI = 'midExtCommonQueryUI',
  EXT_COMMON_OPERATE_UI = 'midExtCommonOperateUI'
}

export enum HyTafFuncName {
  GET_PROFILE_EXT_LIST = 'getProfileExtList',
  GET_USER_EXT_AUTHORIZE_INFO = 'getUserExtAuthorizeInfo',
  USER_EXT_AUTHORIZE = 'userExtAuthorize',
  GET_JWT = 'getJWT',
  ROUTE_EBS_REQUEST_V2 = 'routeEbsRequestV2',
  GET_STORAGE_KEY = 'getStorageKey',
  GET_PIC_UPLOAD_POLICY = 'getPicUploadPolicy',
  TEXT_REPORT_SDK_FORM_EXT = 'textReportSDKFormExt',
  COMMON_BUSINESS = 'commonBusiness',
  DELIVER_ROOM_MSG_BY_UNIONID = 'deliverRoomMsgByUnionId',
  SET_STORAGE = 'setStorage',
  GET_STORAGE_ALL_KEYS = 'getStorageAllKeys',
  DEL_STORAGE_KEY = 'delStorageKey',
  GET_COMMON_FILE_UPLOAD_POLICY = 'getCommonFileUploadPolicy',
  SHOW_ENTRANCE = 'showEntrance',
  COMMON_OPERATE = 'commonOperate'
}

export enum HyTafURI {
  kSecPackTypeDevExtMessagePush = '1040000',
  kSecPackTypeDevExtMessageSystem = '1040002',
  kSecPackTypeItemConsumSubNotify = '6501',
  kSecPackTypeMessageNotice = '1400',
  kSecPackTypeBeginLiveNotice = '8000',
  kSecPackTypeEndLiveNotice = '80001',
  kSecPackTypeAttendeeCountNotice = '8006',
  kSecPackTypeNewNobleNotice = '1001',
  kSecPackTypeNewNobleLevelNotice = '1006',
  kSecPackTypeGuardianPresenterInfoNotice = '1020001',
  kSecPackVipEnterBanner = '6110'
}

export const funcMap: { [key: string]: string } = {
  requestEbs: 'hyExt.requestEbs',
  request: 'hyExt.request',
  getSubscribeInfo: 'hyExt.context.getSubscribeInfo',
  getSubscriberSummary: 'hyExt.context.getSubscriberSummary',
  onSubscriberChange: 'hyExt.context.onSubscriberChange',
  onGiftChange: 'hyExt.context.onGiftChange',
  onBarrageChange: 'hyExt.context.onBarrageChange',
  getItem: 'hyExt.storage.getItem',
  setItem: 'hyExt.storage.setItem',
  showToast: 'hyExt.context.showToast',
  getKeys: 'hyExt.storage.getKeys',
  removeItem: 'hyExt.storage.removeItem',
  deliverMsg: 'hyExt.observer.emit',
  getUserInfo: 'hyExt.context.getUserInfo',
  offSubscriberChange: 'hyExt.context.offSubscriberChange',
  offGiftChange: 'hyExt.context.offGiftChange',
  offBarrageChange: 'hyExt.context.offBarrageChange',
  getLiveInfo: 'hyExt.context.getLiveInfo',
  getWeekRank: 'hyExt.context.getWeekRank',
  getFansRank: 'hyExt.context.getFansRank',
  getVip: 'hyExt.context.getVip',
  addZone: 'hyExt.stream.addZone',
  removeZone: 'hyExt.stream.removeZone',
  uploadImg: 'hyExt.fs.uploadImg',
  getStreamerInfo: 'hyExt.context.getStreamerInfo',
  getGiftConf: 'hyExt.context.getGiftConf',
  leadSubscribe: 'hyExt.context.leadSubscribe',
  leadGift: 'hyExt.context.leadGift',
  leadBarrage: 'hyExt.context.leadBarrage',
  showActivityBadge: 'hyExt.context.showActivityBadge',
  sendGift: 'hyExt.context.sendGift',
  showEntrance: 'hyExt.action.showEntrance',
  hideEntrance: 'hyExt.action.hideEntrance',
  'logger.debug': 'hyExt.logger.debug',
  'logger.info': 'hyExt.logger.info',
  'logger.warn': 'hyExt.logger.warn',
  'logger.error': 'hyExt.logger.error',
  addWhiteBoard: 'hyExt.stream.addWhiteBoard',
  removeWhiteBoard: 'hyExt.stream.removeWhiteBoard',
  addImageLayer: 'hyExt.stream.addImageLayer',
  removeImageLayer: 'hyExt.stream.removeImageLayer',
  enableFaceEffect: 'hyExt.stream.enableFaceEffect',
  currentChannelInfo: 'hyExt.advance.currentChannelInfo',
  showLogin: 'hyExt.advance.showLogin',
  getCurrentUserInfo: 'hyExt.advance.getCurrentUserInfo',
  getJWT: 'hyExt.vip.getJWT',
  getVersionType: 'hyExt.env.getVersionType',
  openVideo: 'hyExt.hyv.openVideo',
  downloadRes: 'hyExt.pc.downloadRes',
  launchExe: 'hyExt.pc.launchExe',
  onSpeechRecognition: 'hyExt.reg.onSpeechRecognition',
  offSpeechRecognition: 'hyExt.reg.offSpeechRecognition',
  onGestureRecognition: 'hyExt.reg.onGestureRecognition',
  offGestureRecognition: 'hyExt.reg.offGestureRecognition',
  onCVRecognition: 'hyExt.reg.onCVRecognition',
  offCVRecognition: 'hyExt.reg.offCVRecognition',
  queryCVRecognition: 'hyExt.reg.queryCVRecognition',
  switchLine: 'hyExt.player.switchLine',
  getLinesInfo: 'hyExt.player.getLinesInfo',
  getCurrentLine: 'hyExt.player.getCurrentLine',
  playVideo: 'hyExt.player.playVideo',
  getVideoInfo: 'hyExt.player.getVideoInfo',
  fastForward: 'hyExt.player.fastForward',
  fastBackward: 'hyExt.player.fastBackward',
  onShareLiveNotice: 'hyExt.context.onShareLiveNotice',
  offShareLiveNotice: 'hyExt.context.offShareLiveNotice',
  onOpenGuardianNotice: 'hyExt.context.onOpenGuardianNotice',
  offOpenGuardianNotice: 'hyExt.context.offOpenGuardianNotice',
  onOpenNobleNotice: 'hyExt.context.onOpenNobleNotice',
  offOpenNobleNotice: 'hyExt.context.offOpenNobleNotice',
  onVipEnterBannerNotice: 'hyExt.context.onVipEnterBannerNotice',
  offVipEnterBannerNotice: 'hyExt.context.offVipEnterBannerNotice',
  addActionEventWithExtraInfo: 'hyExt.advance.addActionEventWithExtraInfo',
  setLayout: 'hyExt.panel.setLayout',
  resumeLive: 'hyExt.player.resumeLive',
  getTid: 'hyExt.advance.getTid',
  sendWup: 'hyExt.advance.sendWup',
  onWupMessage: 'hyExt.advance.onWupMessage',
  setSendBarrageTag: 'hyExt.context.setSendBarrageTag',
  setDisplayBarrageTags: 'hyExt.context.setDisplayBarrageTags',
  openUrl: 'hyExt.advance.openUrl',
  advanceSendGift: 'hyExt.advance.sendGift',
  setViewport: 'hyExt.player.setViewport',
  getViewport: 'hyExt.player.getViewport',
  getFrameData: 'hyExt.player.getFrameData',
  addViewport: 'hyExt.player.addViewport',
  doubleTapped: 'hyExt.player.doubleTapped',
  tapped: 'hyExt.player.tapped',
  getPerspectiveList: 'hyExt.player.getPerspectiveList',
  getCurrentPerspective: 'hyExt.player.getCurrentPerspective',
  setPerspective: 'hyExt.player.setPerspective',
  getVideoPosition: 'hyExt.player.getVideoPosition',
  getSafeAreaPosition: 'hyExt.player.getSafeAreaPosition',
  setSplitedOBStream: 'hyExt.player.setSplitedOBStream',
  reportText: 'hyExt.order.reportText',
  contextMenu: 'hyExt.player.contextMenu',
  getVideoState: 'hyExt.player.getVideoState',
  getMatchInfo: 'hyExt.ob.getMatchInfo',
  commonQuery: 'hyExt.backend.commonQuery',
  onLocalMessage: 'hyExt.observer.onLocalMessage',
  offLocalMessage: 'hyExt.observer.offLocalMessage',
  postLocalMessage: 'hyExt.observer.postLocalMessage',
  createSubView: 'hyExt.view.createSubView',
  destroySubView: 'hyExt.view.destroySubView',
  hideCurrentView: 'hyExt.view.hideCurrentView',
  hideSubView: 'hyExt.view.hideSubView',
  showSubView: 'hyExt.view.showSubView',
  createPopup: 'hyExt.view.createPopup',
  destroyPopup: 'hyExt.view.destroyPopup',
  hideCurrentPopup: 'hyExt.view.hideCurrentPopup',
  hidePopup: 'hyExt.view.hidePopup',
  showPopup: 'hyExt.view.showPopup',
  wsConnect: 'hyExt.wsConnect',
  getStreamerLatencyMode: 'hyExt.stream.getStreamerLatencyMode',
  setStreamerLatencyMode: 'hyExt.stream.setStreamerLatencyMode',
  getViewerLatencyMode: 'hyExt.stream.getViewerLatencyMode',
  setViewerLatencyMode: 'hyExt.stream.setViewerLatencyMode',
  getExtInfo: 'hyExt.env.getExtInfo',
  getHostInfo: 'hyExt.env.getHostInfo',
  registerGroup: 'hyExt.advance.registerGroup',
  disposal: 'hyExt.advance.disposal',
  localControlEntrance: 'hyExt.localControlEntrance',
  localControlPanelLoad: 'hyExt.localControlPanelLoad',
  localControlPanelVisible: 'hyExt.localControlPanelVisible',
  uploadFile: 'hyExt.fs.uploadFile',
  commonOperate: 'hyExt.backend.commonOperate',
  playLive: 'hyExt.player.playLive',
  sendGiftToUser: 'hyExt.advance.sendGiftToUser',
  getJoinMicUserList: 'hyExt.advance.getJoinMicUserList',
}

export enum ExtType {
  WEB_VIDEO_COM = 'web_video_com',
  WEB_POPUP = 'web_popup'
}

export enum BusType {
  SUBSCRIBER = 'subscriber',
  GIFT = 'gift',
  BARRAGE = 'barrage',
  SHARE_LIVE = 'shareLive',
  GUARDIAN = 'guardian',
  NOBLE = 'noble',
  VIP = 'vip',
  LAYOUT = 'layout',
  TAF = 'taf'
}

export enum EventName {
  BARRAGE_CHANGE = 'barrageChange',
  GIFT_CHANGE = 'giftChange',
  OPEN_NOBLE_NOTICE = 'openNobleNotice',
  OPEN_GUARDIAN_NOTICE = 'openGuardianNotice',
  VIP_ENTER_BANNER_NOTICE = 'vipEnterBannerNotice',
  LAYOUT_CHANGE_NOTICE = 'layoutChangeNotice'
}

export enum HyExtType {
  WEB_VIDEO_COM = 'web_video_com',
  WEB_POPUP = 'web_popup'
}
