"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.ApiAuth = void 0;
var ExtError_1 = require("../modules/ExtError");
var ApiAuth = /** @class */ (function () {
    function ApiAuth() {
        this.prevCallTime = 0;
        this.callCount = 0;
    }
    return ApiAuth;
}());
exports.ApiAuth = ApiAuth;
var ExtApiAuth = /** @class */ (function () {
    function ExtApiAuth(funcMap) {
        this.apiState = {};
        this.funcMap = funcMap;
    }
    ExtApiAuth.prototype.auth = function (extEndpoint, funcName, message) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (funcName === 'log') {
                    funcName = 'logger.' + message.level;
                }
                return [2 /*return*/, this.getApiAuth(extEndpoint, funcName).then(function (apiAuth) {
                        if (!apiAuth) {
                            return Promise.resolve();
                        }
                        else {
                            return _this.getApiState(extEndpoint, funcName).then(function (apiState) {
                                if (apiAuth.usable === 1) {
                                    var current = new Date().valueOf();
                                    var frequencySecond = apiAuth.frequencySecond, maxRequest = apiAuth.maxRequest;
                                    var prevCallTime = apiState.prevCallTime, callCount = apiState.callCount;
                                    if (current - frequencySecond * 1000 < prevCallTime) {
                                        if (callCount + 1 > maxRequest) {
                                            return Promise.reject(new ExtError_1.ExtApiAuthError('该API调用频次超过限额，请联系管理员'));
                                        }
                                        else {
                                            return _this.setApiState(extEndpoint, funcName, { prevCallTime: prevCallTime, callCount: callCount + 1 });
                                        }
                                    }
                                    else {
                                        return _this.setApiState(extEndpoint, funcName, { prevCallTime: current, callCount: 1 });
                                    }
                                }
                                else {
                                    return Promise.reject(new ExtError_1.ExtApiAuthError('该API【' + _this.funcMap[funcName] + '】不允许当前小程序调用，请联系管理员'));
                                }
                            });
                        }
                    })];
            });
        });
    };
    ExtApiAuth.prototype.getApiAuth = function (extEndpoint, funcName) {
        var _this = this;
        return Promise.resolve(extEndpoint.typeAuthority.apiAuthority.value.find(function (a) { return a.apiName === _this.funcMap[funcName]; }));
    };
    ExtApiAuth.prototype.getApiState = function (extEndpoint, funcName) {
        var key = this.getKey(extEndpoint, funcName);
        if (!this.apiState[key]) {
            this.apiState[key] = {};
        }
        if (!this.apiState[key][this.funcMap[funcName]]) {
            this.apiState[key][this.funcMap[funcName]] = new ApiAuth();
        }
        return Promise.resolve(this.apiState[key][this.funcMap[funcName]]);
    };
    ExtApiAuth.prototype.getKey = function (extEndpoint, funcName) {
        return extEndpoint.extType + "_" + funcName;
    };
    ExtApiAuth.prototype.setApiState = function (extEndpoint, funcName, apiAuth) {
        var key = this.getKey(extEndpoint, funcName);
        this.apiState[key][this.funcMap[funcName]] = apiAuth;
        return Promise.resolve();
    };
    return ExtApiAuth;
}());
exports["default"] = ExtApiAuth;
