"use strict";
exports.__esModule = true;
exports.base642Ab = exports.ab2Base64 = void 0;
var ab2Base64 = function (ab) {
    var binary = '';
    var bytes = new Uint8Array(ab);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};
exports.ab2Base64 = ab2Base64;
var base642Ab = function (base64) {
    var binary = window.atob(base64);
    var len = binary.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary.charCodeAt(i);
    }
    return bytes.buffer;
};
exports.base642Ab = base642Ab;
